// import React from "react";
// import Header from "../components/home/Header";
// import Footers from "../components/home/Footers";
// import { gatewayNew, gatewayNew5, introBg, noImage, } from "../assets";
// import { smartBenefit2Data, smartBenefitData, smartData } from "../data/data1";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Helmet } from 'react-helmet-async';


// export default function Smart(){

// const product = smartBenefitData[0];

//     return (
//         <div className="bg-slate-100" >
//             <Helmet>
//                 <title>SMART by Mainlogix | AI Personalized Voice Assistant</title>
//                 <meta name="description" content="Discover SMART by Mainlogix, an advanced AI personalized voice assistant that transforms your smart living experience. With features like voice-controlled music playback, smart light management, and the ability to distinguish between humans and animals, SMART delivers seamless interaction with your home and city devices. Explore our versatile IoT gateways, including LoRaWAN to Ethernet/Wi-Fi Gateway with backup battery and Multi-Protocol Smart Gateway with Li-Battery power backup, ensuring reliable connectivity and security for your smart environment." />
//             </Helmet>

//             <Header />

//             <div className="relative w-full ">
//                 <div
//                     className="absolute inset-0 bg-cover bg-center"
//                     style={{ backgroundImage: `url(${introBg})` }}
//                 >
//                     <div className="absolute inset-0 bg-black bg-opacity-75"></div>
//                 </div>
//                 <div className="relative page-title flex items-center justify-between  h-full smd:px-3 px-[10%] smd:py-10 py-20 ">
                
//                     <div >
//                         <h2 className="title-text font-poppins font-semibold text-left text-[55px] text-white">
//                             SMART by Mainlogix
//                         </h2>
//                         <p className="text-white text-left mt-4">
//                             Your Personalized AI Voice Assistant
//                         </p>
//                     </div>
//                 </div>
//             </div>

//             <div className="bg-white py-10">
//                 <div>
//                     <h1 >Introduction</h1>
//                     <p className="text-center smd:px-3 px-[15%] pb-0">
//                         SMART by Mainlogix is an advanced AI personalized 
//                         voice assistant that revolutionizes how you interact 
//                         with your home and city devices. With effortless voice 
//                         control, you can play music, control lights, and even 
//                         distinguish between humans and animals using smart cameras. 
//                         Simply say "Hey SMART" or use a customized name like "Hey Joy" 
//                         to give commands such as:"Turn on the light" "Play me some music”
//                     </p>
//                     <h2 className="title-text font-roboto text-[24px] 2xl:text-2xl font-semibold text-center pt-5 text-red-600 px-[15%]">
//                         <em>
//                             Experience the future of seamless and intuitive smart living with SMART by Mainlogix.
//                         </em>
//                     </h2>
                

//                     <div className="bg-white">
//                         <div className="page-title py-10 smd:px-3  px-40 relative flex flex-col justify-center">
//                             <h2 className="title-text font-poppins font-semibold text-center text-[40px] text-black">
//                                 IoT App Development
//                             </h2>
//                             <p className=" text-center px-[5%] mt-4">
//                                 Our team of expert in-house app developers in 
//                                 Montreal and Toronto specializes in creating 
//                                 innovative IoT applications. We leverage a wide 
//                                 range of technologies to build cutting-edge digital 
//                                 products that seamlessly integrate into the Internet 
//                                 of Things ecosystem. 
//                             </p>
//                         </div>


//                         <div className="grid smd:grid-cols-1 grid-cols-2 items-center gap-4 smd:mx-3 mx-12 py-5">     
//                             <div>
//                                 <div>
//                                     <div>
//                                         <h3 className="leading-normal">LoRaWAN to Ethernet/Wi-Fi Gateway with Backup Battery</h3>
//                                         <article className="font-roboto px-[16px] 2xl:text-2xl">
//                                             Our LoRaWAN to Ethernet/Wi-Fi Gateway is designed for reliable and flexible IoT connectivity, providing seamless integration with LoRaWAN devices and robust network support.
//                                         </article>
                                        
//                                         <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
//                                             <ul className="list-disc px-6 2xl:text-2xl">
//                                                 {smartData.map((item, index) => (
//                                                     item.lorawan.map((smart, subIndex) => (
//                                                         <li key={`${index}-${subIndex}`}>
//                                                             <strong>{smart[0]}</strong> {smart[1]}
//                                                         </li>
//                                                     ))
//                                                 ))}
//                                             </ul>
//                                         </div>   
//                                     </div>
//                                 </div>    
//                             </div>

//                             <div className="flex justify-center overflow-hidden rounded-3xl">
//                                 <img 
//                                     src={gatewayNew}
//                                     className="object-cover" 
//                                     alt="Our solutions" 
//                                 />
//                             </div>

//                         </div>
//                     </div>
//                 </div>

//             </div>


//             <div className='bg-slate-100 py-12  '>
//                 <div className='bg-white shadow-xl rounded-xl smd:mx-1 mx-10 smd:p-3 p-12'>
//                     <h2 className="font-poppins title-text text-2xl 2xl:text-4xl font-semibold mb-2">Benefits</h2>
//                     <article className='des-gray 2xl:text-2xl'>{product.benefitDes}</article>
                
//                     <div className='rounded-xl border-gray-200 border-[1px] smd:mt-5  '>
                        
//                         <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center p-0 m-0'>
//                         {product.benefits[0] && (
//                             <div className="smd:mt-2">
//                             <h2 className='smd:text-[12px] smd:leading-normal smd:px-1 px-10 font-semibold text-xl 2xl:text-2xl text-left' >{product.benefits[0][0]}</h2>
//                             <p className='smd:px-1 px-10 text-left'>{product.benefits[0][1]}</p>
//                             </div>
//                         )}

//                         {product.images[0] ? (
//                             <img 
//                             src={product.images[0]} 
//                             alt='Product' 
//                             className='object-cover w-[100%]'
//                             style={{ height: 'auto', maxHeight: '300px', width: '100%' }} />
//                         ) : (
//                             product.benefits[0] && (
//                             <img 
//                                 src={noImage} 
//                                 alt='Default'
//                                 className='object-cover w-[100%]'
//                                 style={{ height: 'auto', maxHeight: '300px', width: '100%' }} 
//                             />
//                             )
//                         )}
//                         </div>

//                         <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center p-0 m-0 smd:mt-10'>

//                         {product.images[1] ? (
//                             <img 
//                             src={product.images[1]} 
//                             alt='Product' 
//                             className='object-cover w-[100%]'
//                             style={{ height: 'auto', maxHeight: '300px', width: '100%' }} />
//                         ) : (
//                             product.benefits[1] && (
//                             <img 
//                                 src={noImage} 
//                                 alt='Default'
//                                 className='object-cover w-[100%]'
//                                 style={{ height: 'auto', maxHeight: '300px', width: '100%' }} 
//                             />
//                             )
//                         )}

//                         {product.benefits[1] && (
//                             <div>
//                             <h2 className='smd:text-[12px] smd:leading-normal smd:px-1 px-10 font-semibold text-xl 2xl:text-2xl text-left' >{product.benefits[1][0]}</h2>
//                             <p className='smd:px-1 px-10 text-left'>{product.benefits[1][1]}</p>
//                             </div>
//                         )}

//                         </div>

//                         <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center p-0 m-0 smd:mt-10'>
//                             {product.benefits[2] && (
//                                 <div>
//                                 <h2 className='smd:text-[12px] smd:leading-normal smd:px-1 px-10 font-semibold text-xl 2xl:text-2xl text-left' >{product.benefits[2][0]}</h2>
//                                 <p className='smd:px-1 px-10 text-left'>{product.benefits[2][1]}</p>
//                                 </div>
//                             )}

//                             {product.images[2] ? (
//                                 <img 
//                                 src={product.images[2]} 
//                                 alt='Product' 
//                                 className='object-cover w-[100%]'
//                                 style={{ height: 'auto', maxHeight: '300px', width: '100%' }} />
//                             ) : (
//                                 product.benefits[2] && (
//                                 <img 
//                                     src={noImage} 
//                                     alt='Default'
//                                     className='object-cover w-[100%]'
//                                     style={{ height: 'auto', maxHeight: '300px', width: '100%' }} 
//                                 />
//                                 )
//                             )}
//                         </div>

//                         <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center p-0 m-0 smd:my-10'>

//                             {product.images[3] ? (
//                                 <img 
//                                 src={product.images[3]} 
//                                 alt='Product' 
//                                 className='object-cover w-[100%]'
//                                 style={{ height: 'auto', maxHeight: '300px', width: '100%' }} />
//                             ) : (
//                                 product.benefits[3] && (
//                                 <img 
//                                     src={noImage} 
//                                     alt='Default'
//                                     className='object-cover w-[100%]'
//                                     style={{ height: 'auto', maxHeight: '300px', width: '100%' }} 
//                                 />
//                                 )
//                             )}

//                             {product.benefits[3] && (
//                                 <div>
//                                 <h2 className='smd:text-[12px] smd:leading-normal smd:px-1 px-10 font-semibold text-xl 2xl:text-2xl text-left' >{product.benefits[3][0]}</h2>
//                                 <p className='smd:px-1 px-10 text-left'>{product.benefits[3][1]}</p>
//                                 </div>
//                             )}

//                         </div>
                        
//                     </div>
//                 </div>
//             </div>

//             <div className="relative w-full ">
//                 <div
//                     className="absolute inset-0 bg-cover bg-center"
//                     style={{ backgroundImage: `url(${introBg})` }}
//                 >
//                     <div className="absolute inset-0 bg-black bg-opacity-75"></div>
//                 </div>
//                 <div className="relative page-title flex items-center justify-between  h-full smd:px-3 smd:py-10 py-20 ">
        
//                     <div className="relative grid smd:grid-cols-1 grid-cols-2 items-center gap-4 smd:mx-3 mx-12 py-2  ">     
                        
//                         <div>
//                             <h3 className="leading-normal text-white">Multi-Protocol Smart Gateway with Li-Battery Power Backup</h3>
//                             <article className="font-roboto px-[16px] 2xl:text-2xl text-white py-4">
//                                 Our Multi-Protocol Smart Gateway is designed to support a diverse range of IoT devices and protocols, providing a comprehensive solution for smart environments.
//                             </article>
                            
//                             <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
//                                 <ul className="list-disc px-6 2xl:text-2xl">
//                                     {smartData.map((item, index) => (
//                                         item.multiProtocol.map((multi, subIndex) => (
//                                             <li key={`${index}-${subIndex}`} className="text-white" >
//                                                 <strong>{multi[0]}</strong> {multi[1]}
//                                             </li>
//                                         ))
//                                     ))}
//                                 </ul>
//                             </div>   
//                         </div>
                            

//                         <div className="flex justify-center overflow-hidden rounded-3xl">
//                             <img 
//                                 src={gatewayNew5}
//                                 className="object-cover" 
//                                 alt="Our solutions" 
//                             />
//                         </div>     
//                     </div>
//                 </div>
//             </div>

//             <div className="py-10">
//                 <h1 >Benefits</h1>
//                 <p className="text-center px-[15%] pb-0">
//                     These points highlight the advantages of a 
//                     particular IoT solution or device. Here's a more 
//                     detailed breakdown of each benefit:
//                 </p>

//                 <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 py-14 gap-6 mx-[5%]">
//                     {smartBenefit2Data.slice(0, 6).map((expert, index) => (
//                     <div key={index} className="rounded-lg overflow-hidden shadow-lg flex flex-col h-full">
//                         <div className="flex justify-center p-4 items-start">
//                             <FontAwesomeIcon icon={expert.icon} color="white" className="w-4  p-2 mr-2 bg-red-700 rounded-full" />
//                             <div className="flex flex-col justify-between flex-1 ">
                                
//                                 <div>
//                                     <h3 className="smd:text-[12px] smd:leading-normal text-xl 2xl:text-2xl font-semibold mb-2">{expert.heading}</h3>
//                                     <p className="text-black mb-4">{expert.content}</p>
//                                 </div>
                                
//                             </div>
//                         </div>
//                     </div>
//                     ))}
//                 </div>

//             </div>

//             <Footers />
//         </div>
//     )
// }


import React from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { gatewayNew, gatewayNew5, introBg, noImage, smartMainlogix, } from "../assets";
import { smartBenefit2Data, smartBenefitData, smartData } from "../data/data1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from 'react-helmet-async';


export default function Smart(){

const product = smartBenefitData[0];

    return (
        <div className="bg-slate-100" >
            <Helmet>
                <title>SMART by Mainlogix | AI Personalized Voice Assistant</title>
                <meta name="description" content="Discover SMART by Mainlogix, an advanced AI personalized voice assistant that transforms your smart living experience. With features like voice-controlled music playback, smart light management, and the ability to distinguish between humans and animals, SMART delivers seamless interaction with your home and city devices. Explore our versatile IoT gateways, including LoRaWAN to Ethernet/Wi-Fi Gateway with backup battery and Multi-Protocol Smart Gateway with Li-Battery power backup, ensuring reliable connectivity and security for your smart environment." />
            </Helmet>

            <Header />

            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                <div className="relative page-title flex items-center justify-between  h-full smd:px-3 px-[10%] smd:py-10 py-20 ">
                
                    <div >
                        <h2 className="title-text font-poppins font-semibold text-left text-[55px] text-white">
                            SMART by Mainlogix
                        </h2>
                        <p className="text-white text-left mt-4">
                            Your Personalized AI Voice Assistant
                        </p>
                    </div>
                </div>
            </div>

            <div className="bg-white py-10">
                <div>
                    <h1 >Introduction</h1>
                    <p className="text-center smd:px-3 px-[15%] pb-0">
                        SMART by Mainlogix is an advanced AI personalized 
                        voice assistant that revolutionizes how you interact 
                        with your home and city devices. With effortless voice 
                        control, you can play music, control lights, and even 
                        distinguish between humans and animals using smart cameras. 
                        Simply say "Hey SMART" or use a customized name like "Hey Joy" 
                        to give commands such as:"Turn on the light" "Play me some music”
                    </p>
                    <h2 className="title-text font-roboto text-[24px] 2xl:text-2xl font-semibold text-center pt-5 text-red-600 px-[15%]">
                        <em>
                            Experience the future of seamless and intuitive smart living with SMART by Mainlogix.
                        </em>
                    </h2>
                

                    
                </div>

            </div>

            <div className="flex justify-center bg-white">
                <img src={smartMainlogix} alt="Smart By Mainogix" />
            </div>
            <Footers />
        </div>
    )
}


