import { useEffect } from 'react';
import axios from 'axios';

const GeolocationRedirect = () => {
  useEffect(() => {
    const fetchGeolocationData = async () => {
      try {
        // Make sure the API key is set and valid
        if (!process.env.REACT_APP_IPGEOLOCATION_API_KEY) {
          throw new Error('API key is not set');
        }

        const response = await axios.get('https://api.ipgeolocation.io/ipgeo', {
          params: {
            apiKey: process.env.REACT_APP_IPGEOLOCATION_API_KEY,
          },
        });

        const { country_name } = response.data;
        console.log("I am response data", country_name);

        // Handle redirection based on country name
        switch (country_name) {
          case 'Canada':
            window.location.replace('https://www.mainlogix.ca');
            break;
          // Add other countries as needed
          default:
            console.log('Country not matched:', country_name);
        }
      } catch (error) {
        console.error('Error fetching geolocation data:', error);
      }
    };

    fetchGeolocationData();
  }, []);

  return null; 
};

export default GeolocationRedirect;



