import React from "react";
import OurProductsCarousel from "./OurProductsCarousel";



export default function OurProducts(){

    return(
        <div className="bg-white mt-10">
            <div>
                <h1> Our Products</h1>
                <p className="text-center smd:px-3 md:px-[10%] lg:px-[30%]">
                    Smart home solutions for controlling, automating and monitoring your devices via mobile phones.
                </p>
                <OurProductsCarousel />
            </div>

        </div>
    )
}
