import React from "react";
import { blogData } from "../../data/data2";



export default function LatestBlogs() {
    return (
      <div className="bg-white mt-10 smd:mx-3 mx-9">
        <div>
          <h1 className="font-bold mb-4 text-center">Latest Blogs</h1>
          <p className="text-center smd:px-3 px-[30%] pb-6">
            Explore our latest blog posts to stay ahead of the curve with cutting-edge insights and trends in IoT and automation, driving innovation and excellence in the industry.
          </p>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {blogData.slice(0, 3).map((blog) => (
              <div key={blog.id} className="rounded-lg 2xl:rounded-3xl overflow-hidden shadow-lg flex flex-col h-full">
                <img src={blog.image} alt={blog.title} className="w-full h-48 2xl:h-96 object-cover" />
                <div className="flex flex-col justify-between flex-1 p-4">
                    <div>
                        <h3 className="leading-normal font-semibold mb-2">{blog.title}</h3>
                        <p className="text-black mb-4">{blog.description}</p>
                    </div>
                    <div className="flex justify-between">
                        <div className="text-sm 2xl:text-3xl text-gray-500">
                            By <span className="text-black font-semibold"> {blog.author}</span> 
                        </div>
                        <div className="text-sm smd:text-[12px] 2xl:text-3xl text-gray-500">
                            <span>{blog.date}</span>
                        </div>
                    </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }