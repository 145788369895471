import React, { useState, useEffect } from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { productData } from "../data/data1";
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import { noImage } from '../assets';
import '../productCSS/ourProductsCarousel.css';

export default function Products() {

    const navigate = useNavigate();
    const defaultImage = noImage;
    const [currentPage, setCurrentPage] = useState(0);
    const [productsPerPage, setProductsPerPage] = useState(12);

    const handleProductClick = (id) => {
        navigate(`/product/${id}`);
    };

    useEffect(() => {
        const updateProductsPerPage = () => {
            const width = window.innerWidth;
            if (width >= 1200) {
                setProductsPerPage(10); // 2 rows * 5 columns
            } else if (width >= 768) {
                setProductsPerPage(6); // 2 rows * 3 columns
            } else {
                setProductsPerPage(4); // 2 rows * 2 columns
            }
        };

        window.addEventListener('resize', updateProductsPerPage);
        updateProductsPerPage(); // Initial check

        return () => window.removeEventListener('resize', updateProductsPerPage);
    }, []);


    const startIndex = currentPage * productsPerPage;
    const endIndex = startIndex + productsPerPage;
    const currentProducts = productData.slice(startIndex, endIndex);

    const handleNext = () => {
        if (endIndex < productData.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrev = () => {
        if (startIndex > 0) {
            setCurrentPage(currentPage - 1);
        }
    };





    return (
        <div className="bg-slate-100">
            <Helmet>
                <title>Mainlogix Products | Innovative IoT and Automation Solutions</title>
                <meta name="description" content="Explore Mainlogix's extensive range of innovative IoT and automation products designed to enhance your smart living experience. From advanced sensors and smart locks to cutting-edge gateways and AI-driven solutions, our products offer reliability, efficiency, and seamless integration for various applications. Discover how our state-of-the-art technology can transform your home and business with superior performance and security." />
            </Helmet>

            <Header />
            <div className="my-10" >
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 p-4">
                    {currentProducts.map(product => (
                        <div 
                            key={product.id} 
                            className="shadow-2xl rounded-xl overflow-hidden flex flex-col justify-between "
                            onClick={() => handleProductClick(product.id)}
                             
                        >
                            <div className="flex-1 flex flex-col justify-center items-center ">
                                <div className="w-full h-[200px] h-xl:h-[500px] flex items-center justify-center">
                                    <img 
                                        className="object-cover w-full max-h-full" 
                                        src={product.images[0] || defaultImage} 
                                        alt={product.name} 
                                    />
                                </div>
                                <h3 className="font-poppins mt-4 text- 2xl:text-4xl text-center font-bold leading-normal">{product.name}</h3>
                            </div>
                            <p className="font-roboto text-sm 2xl:text-3xl text-center text-red-500 pb-5">View Product</p>
                        </div>
                    ))}
                </div>

                <div className="flex justify-center gap-6">
                    <button 
                        onClick={handlePrev} 
                        disabled={startIndex === 0}
                        className="button-m bg-red-600 py-1 px-2 rounded-full font-poppins font-semibold text-white"
                        >Prev</button>
                    <button 
                        onClick={handleNext} 
                        disabled={endIndex >= productData.length}
                        className="button-m bg-red-600 py-1 px-2 rounded-full font-poppins font-semibold text-white"
                        >Next</button>
                </div>
            </div>
            <Footers />
        </div>
    );
}




