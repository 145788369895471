import React, { useState } from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { comProtocol, introBg, supportedArch, supportedIde, supportedMcu, techTools } from "../assets";
import { techToolsFaqData } from "../data/data2";
import { Helmet } from 'react-helmet-async';



export default function TechTools(){

    const [activeIndex, setActiveIndex] = useState(0);

    const toggleFAQ = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className="bg-slate-100" >
            <Helmet>
                <title>Technologies and Tools | Mainlogix</title>
                <meta name="description" content="Explore the advanced technologies and tools used by Mainlogix to deliver cutting-edge IoT and automation solutions. Learn about our supported communication protocols, architectures, MCUs, and IDEs that ensure robust, scalable, and efficient solutions for your tech needs. Discover how our technology stack can drive innovation in your projects." />
            </Helmet>

            
            <Header />

            <div className="relative w-full ">
                    <div
                        className="absolute inset-0 bg-cover bg-center"
                        style={{ backgroundImage: `url(${introBg})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                    </div>
                    
                    <div className="page-title w-full bg-opacity-75 py-10 px-40 relative flex flex-col justify-center overflow-hidden">
                        <h2 className="title-text font-poppins font-semibold text-center text-[55px] text-white">
                            Technologies & Tools
                        </h2>
                        <p className="text-white text-center smd:px-3 md:px-3 px-[25%] mt-4">
                            Explore the Cutting-Edge Technologies and Tools that Power 
                            Our IoT and Automation Solutions, Enabling Seamless Connectivity, 
                            Advanced Architectures, and Unmatched Compatibility with 
                            Industry-Standard Protocols, MCUs, and Development Environments.
                        </p>
                    </div>
                </div>

            <div className="mx-[5%] smd:mx-3 my-20 ">

                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div>
                        <img src={comProtocol} alt="communication protocol" />
                    </div>
                    <div>
                        <img src={supportedArch} alt="communication protocol" />
                    </div>
                    <div>
                        <img src={supportedMcu} alt="communication protocol" />
                    </div>
                    <div>
                        <img src={supportedIde} alt="communication protocol" />
                    </div>
                </div>


                <div className="mt-16">
                    <h1 className="leading-normal">Frequently Asked Questions</h1>
                    <p className="text-center smd:px-3 md:px-3 px-[30%] pb-0">
                        Got Questions? Our FAQ Section Covers the Essential 
                        Technologies and Tools We Use, Providing Clarity on 
                        Communication Protocols, Architectures, MCUs, and More 
                        to Ensure You Have All the Information You Need.
                    </p>
                </div>


                <div className="grid smd:grid-cols-1 grid-cols-2 gap-4 py-10">
                    <div className="smd:hidden flex justify-center overflow-hidden rounded-2xl bg-gray-200">
                        <img 
                        src={techTools} 
                        alt="FAQ"
                        className="object-cover scale-x-[-1] h-[80vh] "
                        />
                    </div>

                    

                    <div>
                        <div className="faq-section w-full mx-auto overflow-y-scroll h-[80vh] custom-scrollbar">
                            {techToolsFaqData.map((faq, index) => (
                                <div key={index} className="faq-item mb-1 mr-4">
                                    <div
                                        className={`faq-question flex justify-between items-center p-4 cursor-pointer rounded-md ${
                                        index === activeIndex ? "bg-black text-white rounded-2xl" : "bg-gray-200 text-black rounded-2x1"
                                        }`}
                                        onClick={() => toggleFAQ(index)}
                                    >
                                        <span className="font-medium smd:text-[12px] text-[16px] 2xl:text-2xl">{faq.question}</span>
                                        <span className="faq-icon text-sm">
                                        {index === activeIndex ? "▲" : "▼"}
                                        </span>
                                    </div>
                                    <div
                                        className={`faq-answer ${
                                        index === activeIndex ? "block" : "hidden"
                                        } p-4 bg-white text-black smd:text-[12px] 2xl:text-2xl rounded-md`}
                                    >
                                        {faq.answer}
                                    </div>
                                </div>
                            ))}
                            </div>

                        </div>
                    </div>
                </div>

            <Footers />
        </div>
    )
}


