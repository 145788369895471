import React from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { introBg, iotProductDevelopmentStep } from "../assets";
import { iotUseCasesData } from "../data/data3";
import { Helmet } from 'react-helmet-async';



export default function IotProductDevSteps(){

    return (
        <div className="bg-slate-100" >

            <Helmet>
            <title>Mainlogix IoT Product Development | Custom IoT Solutions and Innovations</title>
            <meta name="description" content="Explore Mainlogix's IoT product development services, where innovation meets functionality. We specialize in designing and developing custom IoT devices and solutions tailored to your specific needs. From concept to deployment, our expertise covers hardware and software integration, smart device creation, and scalable solutions for both industrial and consumer applications. Partner with us to transform your ideas into cutting-edge IoT products that drive efficiency and enhance connectivity." />
            </Helmet>


            <Header />

            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white">
                        IoT Product Development Steps
                    </h2>
                    <p className="text-center text-white mt-4">
                        A comprehensive guide to creating innovative IoT products, 
                        covering the essential stages from concept to deployment, 
                        ensuring efficient, secure, and scalable solutions for the 
                        connected world.
                    </p>
                </div>
            </div>
            
            <div className="flex justify-center my-20 mx-[5%] ">
                <img src={iotProductDevelopmentStep} alt="IoT Product Development Steps"/>
            </div>
            <Footers />
        </div>
    )
}


