import React from "react";
import { HomeAbout } from "../../assets";
import { Link } from "react-router-dom";

export default function AboutUs(){


    return(
        <div className="mt-0 lg:mt-10" >
            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${HomeAbout})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
                    <div>
                        <h1 className="text-white smd:p-0 pb-4">About Us</h1>
                    </div>
                    <div>
                        <p className="text-white text-center smd:pb-3 pb-10">Mainlogx Technology specializes in Artificial Intelligence of Things (AIOT) solutions, 
                            focusing on creating innovative products 
                            such as the SMART-AI Personalized Voice 
                            Assistant, Embedded Board, Cloud Infrastructure, 
                            and Smart Edge Gateway with multiprotocol 
                            support. We provide IoT services, development, consulting, and 
                            expertise across various industries in IOT.
                        </p>
                    </div>
                    <div className="flex justify-center">
                        <Link to='/about'>
                            <button className="button-m">
                                Explore
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
