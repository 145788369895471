import React from 'react';
import { Link } from 'react-router-dom';
import { introBg } from '../assets';
import Header from '../components/home/Header';
import Footers from '../components/home/Footers';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
  return (
    <div>
        <Helmet>
            <title>Mainlogix Privacy Policy | Protecting Your Data and Privacy</title>
            <meta name="description" content="Learn about Mainlogix's commitment to safeguarding your personal data with our comprehensive Privacy Policy. We detail how we collect, use, and protect your information, ensuring transparency and compliance with data protection regulations. Understand your rights and how we maintain the highest standards of privacy and security for your personal and financial information." />
        </Helmet>

        <Header />

        <div className="relative w-full mb-8 ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white">
                        Privacy Policy
                    </h2>
                    <p className="text-center text-white mt-4">
                        At Mainlogix, we are committed to protecting your privacy 
                        and ensuring that your personal information is handled in a 
                        safe and responsible manner. This Privacy Policy outlines the 
                        types of personal information we collect, how we use it, and the 
                        measures we take to safeguard your data. By using our services, 
                        you agree to the collection and use of information in accordance with 
                        this policy.
                    </p>
                </div>
            </div>

        
        <div className='m-[5%]'>
            <ol>
                <li>
                <h2 className="font-poppins mt-[2%] font-semibold text-black text-[20px]">Information We Collect</h2>
                <p>
                    We collect a variety of information to provide you with the best possible experience. This includes:
                </p>
                <ul>
                    <li>
                    <strong>Personal Information:</strong> When you sign up for our services, we may collect personal information such as your name, email address, phone number, and mailing address.
                    </li>
                    <li>
                    <strong>Usage Data:</strong> We collect information on how you access and use our services, including your IP address, browser type, and the pages you visit on our site. This data helps us improve our services and customize your experience.
                    </li>
                    <li>
                    <strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to track activity on our website and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent, but if you do not accept cookies, you may not be able to use some portions of our services.
                    </li>
                </ul>
                </li>

                <li>
                <h2 className="font-poppins mt-[2%] font-semibold text-black text-[20px]">How We Use Your Information</h2>
                <p>
                    The information we collect is used to enhance and improve our services. Specifically, we use your information to:
                </p>
                <ul>
                    <li>
                    <strong>Provide and Maintain Services:</strong> Your information allows us to provide the services you request, process transactions, and maintain and improve our platform.
                    </li>
                    <li>
                    <strong>Communicate with You:</strong> We use your contact information to send updates, notifications, and other essential communications related to your account and our services.
                    </li>
                    <li>
                    <strong>Personalize Your Experience:</strong> We analyze your usage data to tailor our services to your preferences and interests.
                    </li>
                    <li>
                    <strong>Security and Fraud Prevention:</strong> We may use your information to detect, prevent, and address technical issues, fraudulent activities, and security threats.
                    </li>
                    <li>
                    <strong>Marketing and Promotions:</strong> With your consent, we may send you promotional materials about our services and offers. You can opt out of these communications at any time.
                    </li>
                </ul>
                </li>

                <li>
                <h2 className="font-poppins mt-[2%] font-semibold text-black text-[20px]">Sharing Your Information</h2>
                <p>
                    We do not sell your personal information to third parties. However, we may share your information with:
                </p>
                <ul>
                    <li>
                    <strong>Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
                    </li>
                    <li>
                    <strong>Compliance with Laws:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.
                    </li>
                    <li>
                    <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of that transaction.
                    </li>
                </ul>
                </li>

                <li>
                <h2 className="font-poppins mt-[2%] font-semibold text-black text-[20px]">Security of Your Information</h2>
                <p>
                    The security of your personal information is important to us. We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.
                </p>
                </li>

                <li>
                <h2 className="font-poppins mt-[2%] font-semibold text-black text-[20px]">Your Rights and Choices</h2>
                <p>
                    You have certain rights regarding the personal information we hold about you, including:
                </p>
                <ul>
                    <li>
                    <strong>Access and Correction:</strong> You have the right to access and correct your personal information at any time by contacting us directly.
                    </li>
                    <li>
                    <strong>Data Portability:</strong> You may request a copy of your personal information in a structured, machine-readable format.
                    </li>
                    <li>
                    <strong>Opt-Out:</strong> You can opt out of receiving marketing communications from us by following the unsubscribe instructions in those communications or by contacting us directly.
                    </li>
                    <li>
                    <strong>Account Deletion:</strong> You can request the deletion of your account and personal information by contacting us, subject to certain legal obligations.
                    </li>
                </ul>
                </li>

                <li>
                <h2 className="font-poppins mt-[2%] font-semibold text-black text-[20px]">Changes to This Privacy Policy</h2>
                <p>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this policy. You are advised to review this Privacy Policy periodically for any changes.
                </p>
                </li>

                <li>
                <h2 className="font-poppins mt-[2%] font-semibold text-black text-[20px]">Contact Us</h2>
                <p>
                    If you have any questions or concerns about this Privacy Policy, please contact us:
                </p>
                <Link to='tel:+19024881641'>
                    <p>+19024881641</p>
                </Link>
                <Link to='mailto:contact@mainlogix.ca'>
                    <p>contact@mainlogix.ca</p>
                </Link>
                <Link to='https://maps.google.com/?cid=14512778884290444467'>
                    <p>1315 Pickering Parkway, Suite 203 Pickering, ON. L1V7G5 Canada.</p>
                </Link>
                </li>
            </ol>
        </div>
        <Footers />
    </div>
  );
};

export default PrivacyPolicy;
