import React from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { introBg } from "../assets";
import { partnersData } from "../data/data2";
import { Helmet } from 'react-helmet-async';


export default function Partners(){

    const middleIndex = Math.ceil(partnersData.length / 2);
    const firstColumnData = partnersData.slice(0, middleIndex);
    const secondColumnData = partnersData.slice(middleIndex);

    const renderColumn = (data) => (
        <div className="flex flex-col">
            {data.map((partner, index) => (
                <div key={index} className="">
                    <div className="flex flex-col justify-between flex-1 py-5">
                        <div>
                            <img 
                                src={partner.image} 
                                alt={partner.title}
                                className="w-20" 
                                
                            />
                            <h3 className="text-[18px] text-red-600 leading-normal font-semibold">
                                {partner.title}
                            </h3>
                            <p className="text-black ">{partner.title}</p>
                        </div>
                    </div>
                    {index < data.length - 1 && <hr style={{ borderColor: 'black' }} />}
                </div>
            ))}
        </div>
    );

    return (
        <div className="bg-white" >

        <Helmet>
            <title>Mainlogix Partners | Collaborations with Leading Technology Providers</title>
            <meta name="description" content="Discover Mainlogix's strategic partnerships with industry leaders like Zigbee, BLE, Z-Wave, Matter, Google Home, Alexa and more. Our partners page showcases our collaborations with top technology providers to deliver innovative solutions in IoT and smart home automation. Learn about the technologies we integrate with to enhance connectivity, performance, and user experience across a wide range of smart devices." />
        </Helmet>


            <Header />
            
            <img src={introBg} alt=" " className="absolute object-cover w-full h-[60vh]" />
            
            <div className="bg-black w-full bg-opacity-75 h-[60vh] py-10 px-40 relative flex flex-col justify-center">
                <h2 className="poppin font-semibold text-center text-[55px] text-white">
                    Our Partners
                </h2>
                <p className="text-white text-center px-[25%] mt-4">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, 
                sed diam nonummy nibh euismod tincidunt ut laoreet 
                dolore magna. 
                </p>
            </div>


            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 py-14 gap-x-6 mx-[5%]">
                {renderColumn(firstColumnData)}
                {renderColumn(secondColumnData)}
            </div>

                
            <Footers />
        </div>
    )
}


