import React from "react";
import ServicesPage from "./home/ServicesPage";


export default function OtherServices(){

    return (
        <div>        
            <div className="page-title mx-[5%] px-4 relative flex flex-col justify-center">
                <h2 className="title-text font-poppins font-semibold text-left text-black">
                    Expore Other Services
                </h2>
            </div>

            <div className="mx-[5%]">
                <ServicesPage />
            </div>
        </div>

    )
}