import React from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { introBg, iotAppDevelopment,} from "../assets";
import { iotServicesData } from "../data/data1";
import { Helmet } from 'react-helmet-async';
import ServicesPage from "../components/home/ServicesPage";


export default function IotServices(){

    return (
        <div className="bg-slate-100" >

            <Helmet>
                <title>Mainlogix IoT Services | Advanced IoT Solutions for Home and Business</title>
                <meta name="description" content="Discover Mainlogix's extensive IoT services tailored for both residential and commercial needs. Our offerings include IoT consulting, custom solutions, smart home automation, device integration, and data analytics. We specialize in enhancing home convenience, optimizing business operations, and providing end-to-end support to elevate your IoT infrastructure. Experience seamless connectivity and smart automation with our innovative IoT solutions." />
            </Helmet>


            <Header />

            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white">
                        IOT Services
                    </h2>
                    <p className="text-center text-white mt-4">
                        Explore our comprehensive IoT services designed to drive innovation and efficiency across various industries. From consultation to deployment, we provide end-to-end solutions that transform your ideas into connected realities, optimizing performance and enhancing user experiences.
                    </p>
                </div>
            </div>

                {/* Connected Objects */}

                <div className="bg-white">
                    <div className="py-10 smd:p-3  px-40 relative flex flex-col justify-center">
                        <h2 className="title-text font-poppins font-semibold text-center text-[40px] text-black">
                            IoT App Development
                        </h2>
                        <p className=" text-center px-[5%] mt-4">
                            At Mainlogix, our team of expert in-house app developers specializes in crafting innovative IoT applications. We utilize a broad spectrum of advanced technologies to design state-of-the-art digital solutions that effortlessly integrate with the Internet of Things ecosystem. 
                        </p>
                    </div>


                    <div className="grid smd:grid-cols-1 grid-cols-2 gap-4 smd:mx-3 mx-12 py-5">
                        <div className="flex justify-center overflow-hidden rounded-3xl bg-white">
                            <img 
                                src={iotAppDevelopment}
                                className="object-cover 2xl:rounded-3xl" 
                                alt="Our solutions" 
                            />
                        </div>
                        <div>
                            <div>   
                                <div  >
                                    <h3>Our Expertise Includes:</h3>
                                    
                                    <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
                                        <ul className="list-disc smd:px-3 px-6 2xl:text-2xl">
                                            {iotServicesData.map((item, index) => (
                                                item.ourExpertise.map((expertise, subIndex) => (
                                                    <li key={`${index}-${subIndex}`}>
                                                        <strong>{expertise[0]}</strong> {expertise[1]}
                                                    </li>
                                                ))
                                            ))}
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

               

                <div className="page-title pt-10 mx-[5%] px-4 relative flex flex-col justify-center">
                    <h2 className="title-text font-poppins font-semibold text-left text-black">
                        Expore Other Services
                    </h2>
                </div>

                <div className="mx-[5%]">
                    <ServicesPage />
                </div>

                {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-20 gap-6 mx-[5%]">
                    {consultingServicesData.slice(0, 7).map((rto, index) => (
                        <div key={index} className="rounded-lg 2xl:rounded-2xl overflow-hidden shadow-lg flex flex-col h-full">
                            <img
                                src={rto.img}
                                alt="Real Time Operations"
                                className="w-full h-52 2xl:h-96 object-cover"
                            />
                            <div className="flex-1 flex flex-col justify-between p-4">
                                <h3 className="text-xl 2xl:text-2xl text-center font-semibold m-0 leading-tight">{rto.heading}</h3>
                                <p className="text-black text-center mt-2 pb-4 leading-normal">{rto.content}</p>
                            </div>
                        </div>
                    ))}
                </div> */}
            <Footers />
        </div>
    )
}


