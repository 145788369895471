import React from "react";
import { Helmet } from "react-helmet-async";
import Header from "../components/home/Header";
import { introBg } from "../assets";
import Footers from "../components/home/Footers";
import { aiotServicesData } from "../data/data4";
import OtherServices from "../components/OtherServices";

const MachineLearningServices = () => {
    return (
        <div>
            <Helmet>
                <title>IOT Machine Learning Services | Mainlogix</title>
                <meta name="IOT Machine Learning Services" content="Explore Mainlogix NFC solutions and real-world use cases in retail, healthcare, transportation, and smart homes. Request a demo today!" />
            </Helmet>
            <Header />
            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[20%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white ">
                        IOT Machine Learning Services
                    </h2>
                    <p className="text-center text-white mt-4">
                        Harnessing the power of machine learning to enhance your IoT solutions, enabling predictive analytics, intelligent automation, and smarter decision-making.
                    </p>
                </div>
            </div>

                <div className="bg-white py-10">
                    <div>
                        <h1 >Intelligence Meets Connectivity</h1>
                        <p className="text-center smd:px-3 px-[15%] pb-0">
                            At Mainlogix Technology, we’re bringing the power of Machine Learning and IoT together to create smarter, more connected systems. This isn’t just about linking devices—it’s about making them smarter, more efficient, and capable of learning from their environment. Here’s how it is making an impact:
                        </p>
                              
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-20 gap-6 mx-[5%]">
                    {aiotServicesData.slice(0, 8).map((nfc, index) => (
                        <div key={index} className="rounded-lg 2xl:rounded-2xl overflow-hidden shadow-lg flex flex-col h-full">
                            <img
                                src={nfc.img}
                                alt="Real Time Operations"
                                className="w-full h-52 2xl:h-96 object-cover"
                            />
                            <div className="flex-1 flex flex-col justify-between p-4">
                                <h3 className="text-xl 2xl:text-2xl text-center font-semibold m-0 leading-tight">{nfc.heading}</h3>
                                <p className="text-black text-left mt-2 pb-4 leading-normal">{nfc.content}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <OtherServices />


                <Footers />
            
        </div>
    )
};

export default MachineLearningServices;