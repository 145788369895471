import React from 'react';
import { useParams } from 'react-router-dom';
import { logo } from '../assets'; // Import logo from src/assets/index.js

const ImageDisplay = () => {
  const { imageName } = useParams(); // Get imageName from URL parameter
  
  // Dynamically set the image URL based on the imported image or construct one based on imageName
  const imageMapping = {
    logo, // Assuming you have multiple images, you can add them here
  };

  // Construct the image URL using the image name from the URL or fallback to 'logo'
  const imageUrl = imageMapping[imageName] || `${process.env.PUBLIC_URL}/assets/${imageName}.jpg`;

  if (!imageUrl) {
    // Return a message or fallback if the image is not found
    return <div>Image not found</div>;
  }

  return (
    <div className='grid grid-cols-1 place-items-center justify-center items-center h-[100vh]' style={{ textAlign: 'center', margin: '20px' }}>
      <img src={imageUrl} alt={imageName} style={{ maxWidth: '100%', height: 'auto' }}  />
    </div>
  );
};

export default ImageDisplay;
