import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/home/Header';
import Footers from '../components/home/Footers';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // Navigate back to the home page
  };

  return (
    <div>
        <Header />
        <div className="h-screen flex flex-col justify-center items-center bg-gray-100">
        <h1 className="text-6xl font-bold text-red-600 mb-4">404</h1>
        <p className="text-2xl mb-8">Oops! The page you're looking for doesn't exist.</p>
        <button
            onClick={handleGoHome}
            className="px-4 py-2 bg-red-600 border-solid border-[1px] border-red-600 text-white rounded hover:bg-red-100 hover:text-red-600"
        >
            Go Back Home
        </button>
        </div>
        <Footers />
    </div>
  );
};

export default NotFound;
