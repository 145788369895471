import React from 'react';
import { productData } from '../../data/data1';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate } from 'react-router-dom';
import { noImage } from '../../assets';
import '../../productCSS/ourProductsCarousel.css';

function OurProductsCarousel() {
  const navigate = useNavigate();
  const defaultImage = noImage;

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div className="relative smd:mx-0 lg:mx-[10%] smd:mt-0 mt-[-3%]">
      <OwlCarousel
        className='product-carousel smd:pb-0 pb-10' 
        
        loop={true}
        nav={true}
        dots={false}
        responsive={{
          0: {
              items: 1.5,
              autoplay: true

          },
          600: {
              items: 2.5,
              autoplay: true
          },
          1000: {
              items: 3,
              autoplay: false
          }
      }}
        navText={[
          `<button class="top-0 bg-red-600 text-white rounded-full py-2 px-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="smd:w-3 smd:h-3 w-6 h-6"> 
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>`,
          `<button class="bg-red-600 text-white rounded-full py-2 px-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="smd:w-3 smd:h-3 w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>`
        ]}
      >
        {productData.slice(0, 5).map(product => (
          <div>
            <div>
              <div key={product.id} 
                className="w-[80%] mt-[25%] shadow-2xl rounded-xl overflow-hidden flex flex-1 flex-col justify-center" 
                onClick={() => handleProductClick(product.id)}
                style={{  marginLeft: '50px', marginRight: '50px'  }}
                >
                <img 
                  className="object-cover "
                  src={product.images[0] || defaultImage} 
                  alt={product.name} 
                  style={{width: '100%', textAlign: 'center'}}
                  
                />
                <hr className='h-5' />
                <h3 className="font-poppins mt-2 text-xl 2xl:text-4xl text-center font-bold">{product.name}</h3>
                <p className="font-roboto text-sm  2xl:text-3xl text-center text-red-500 pb-5">View Product</p>
              </div>
          
            </div>
            <div className='smd:h-[10vh] h-[15vh]'>

            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
}

export default OurProductsCarousel;
