import React from "react";
import { Helmet } from "react-helmet-async";
import Header from "../components/home/Header";
import { introBg } from "../assets";
import Footers from "../components/home/Footers";
import { nfcServicesData } from "../data/data4";

const NfcMainlogix = () => {
    return (
        <div>
            <Helmet>
                <title>NFC Solutions & Use Cases | Mainlogix</title>
                <meta name="NFC" content="Explore Mainlogix NFC solutions and real-world use cases in retail, healthcare, transportation, and smart homes. Request a demo today!" />
            </Helmet>
            <Header />
            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[20%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white ">
                        Near Field Communication (NFC)
                    </h2>
                    <p className="text-center text-white mt-4">
                        Leverage the power of NFC technology with our versatile solutions for different applications, including Google reviews, social media following tags, and more.
                    </p>
                </div>
            </div>

                <div className="bg-white py-10">
                    <div>
                        <h1 >NFC Solutions for the Modern World</h1>
                        <p className="text-center smd:px-3 px-[15%] pb-0">
                        At Mainlogix Technology, we harness the power of Near Field Communication (NFC) to deliver innovative and versatile solutions for businesses and individuals. NFC technology is transforming the way we interact with the world around us, offering seamless, contactless experiences that enhance productivity, security, and engagement.
                        </p>
                        <h2 className="title-text font-roboto text-[24px] 2xl:text-2xl font-semibold text-center pt-5 text-red-600 px-[15%]">
                            <em>
                            Explore how our NFC solutions are being applied across various use cases:
                            </em>
                        </h2>      
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-20 gap-6 mx-[5%]">
                    {nfcServicesData.slice(0, 8).map((nfc, index) => (
                        <div key={index} className="rounded-lg 2xl:rounded-2xl overflow-hidden shadow-lg flex flex-col h-full">
                            <img
                                src={nfc.img}
                                alt="Real Time Operations"
                                className="w-full h-52 2xl:h-96 object-cover"
                            />
                            <div className="flex-1 flex flex-col justify-between p-4">
                                <h3 className="text-xl 2xl:text-2xl text-center font-semibold m-0 leading-tight">{nfc.heading}</h3>
                                <p className="text-black text-left mt-2 pb-4 leading-normal">{nfc.content}</p>
                            </div>
                        </div>
                    ))}
                </div>


                <Footers />
            
        </div>
    )
};

export default NfcMainlogix;