import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminSignIn.css'; // For styling

const AdminSignIn = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const adminUsername = process.env.REACT_APP_ADMIN_USERNAME;
    const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD;

    const handleLogin = (e) => {
        e.preventDefault();
    
        if (username === adminUsername && password === adminPassword) {
        sessionStorage.setItem('isAdmin', 'true'); // Save login session
        navigate('/admin/dashboard');
        } else {
        alert('Invalid credentials!');
        }
    };
  

  return (
    <div className="admin-login-container">
      <h2>Admin Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default AdminSignIn;
