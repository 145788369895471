import React from "react";
import Header from "../components/home/Header";
import MainCarousel from "../components/home/MainCarousel";
import $ from 'jquery';
import AboutUs from "../components/home/AboutUs";
import OurProducts from "../components/home/OurProducts";
import OurSubsidiary from "../components/home/OurSubsidiary";
import Services from "../components/home/Services";
import OurSolutions from "../components/home/OurSolutions";
import OurPartners from "../components/home/OurPartners";
import LatestBlogs from "../components/home/LatestBlogs";
import FAQ from "../components/home/FAQ";
import Footers from "../components/home/Footers";
import PetIotSolution from "../components/home/PetIotSolution";
import Nfc1 from "../components/home/Nfc1";
import { Helmet } from 'react-helmet-async';
import WhyChooseUs from "../components/home/WhyChooseUs";



window.jQuery = $;


export default function Home(){

    return(
        <div>
            <Helmet>
                <title>Mainlogix</title>
                <meta name="description" content="Mainlogix offers cutting-edge IoT solutions and automation technologies, including smart sensors, locks, and gateways. Discover advanced, reliable, and seamless integration solutions designed to enhance efficiency, security, and innovation for your business. Contact us for expert consultation and transformative technology solutions." />
            </Helmet>

            <Header />
            <MainCarousel />
            <AboutUs />
            <OurProducts />
            <OurSubsidiary />
            <Services />
            <OurSolutions />
            {/* <PetIotSolution /> */}
            <Nfc1 />
            <OurPartners />
            <LatestBlogs />
            <WhyChooseUs />
            {/* <FAQ /> */}

            <Footers />

        </div>
    )
}

