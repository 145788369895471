import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { pet2, pet9, petBanner } from '../assets';
import { petAccessoriesData, petData } from '../data/data1';
import Header from '../components/home/Header';
import Footers from '../components/home/Footers';
import { Helmet } from 'react-helmet-async';



function PetIot() {
  return (
    <div className='bg-slate-100'>

        <Helmet>
            <title>Mainlogix Pet IoTs | Smart Solutions for Your Pet's Safety and Well-being</title>
            <meta name="description" content="Discover Mainlogix's innovative IoT solutions designed specifically for pets. From smart trackers and health monitors to automated feeders and interactive toys, our products ensure your pet's safety, health, and happiness. Explore how our advanced technology keeps you connected to your furry friends, offering real-time updates and enhanced care solutions." />
        </Helmet>


        <Header />
      
        {/* <div className='bg-gradient-to-b from-[#E0E8FD] to-[#E0E8FD] pt-20'>
            <div className="flex md:flex-row items-center justify-between h-[80vh] bg-gradient-to-b from-[#E0E8FD] to-[#FCFEFF] overflow-hidden py-40"> */}
        <div className='bg-white'>
            <div className="smd:hidden grid grid-cols-2 md:flex-row items-center justify-center bg-white overflow-hidden">
                <div className=" text-left pl-10 ">
                    <h2 className="pp-bnr mb-4">Pet IoT Solutions</h2>
                    <p className="des-blk mb-6">
                        Our Pet IoT Solutions are crafted to ensure the health 
                        and safety of your beloved pets through advanced 
                        monitoring and analytics. Leveraging a sophisticated sensor 
                        platform, we provide comprehensive insights into your pet’s 
                        well-being.
                    </p>
                    {/* <a
                        href="/"
                        className="button-m text-left inline-flex items-center rounded-md"
                    >
                        <FontAwesomeIcon icon={faGlobe} className="mr-2 ml-0" />
                        Explore Product       
                    </a> */}
                </div>
                <div className=" w-full flex justify-center mt-6 md:mt-0 lg:m-0 p-0">
                    <img 
                        src={petBanner} 
                        alt="Pets IOTs" 
                        className="object-contain h-[450PX] 2xl:h-[700px]" 
                    />
                </div>
            </div>

            {/* mobile view */}
            <div 
                className="md:hidden lg:hidden relative flex flex-col items-center justify-between p-5 h-[100%]"
                style={{ 
                  backgroundImage: `url(${petBanner})`, 
                  backgroundSize: 'cover', 
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  opacity: 0.9 
                }}
            >
                {/* Overlay for Mobile Screens */}
                <div className="absolute inset-0 bg-black opacity-50 md:hidden"></div>y

                {/* Content Section */}
                <div className="relative text-left max-w-lg md:bg-transparent bg-white bg-opacity-80 p-4 md:p-0 rounded-lg">
                    <h2 className="pp-bnr mb-4">Pet IoT Solutions</h2>
                    <p className="des-blk mb-6">
                        Our Pet IoT Solutions are crafted to ensure the health 
                        and safety of your beloved pets through advanced 
                        monitoring and analytics. Leveraging a sophisticated sensor 
                        platform, we provide comprehensive insights into your pet’s 
                        well-being.
                    </p>
                    {/* <a
                    href="/products"
                    className="button-m text-left inline-flex items-center rounded-md"
                    >
                    <FontAwesomeIcon icon={faGlobe} className="mr-1 lg:mr-2 ml-0" />
                    Explore Product
                    </a> */}
                </div>

            </div>
        </div>

        <div className="mx-[5%] smd:mt-10 mt-20 bg-white">
            <div className="rounded-2xl overflow-hidden shadow-2xl flex flex-col h-full">
                <div className="flex flex-col justify-between flex-1 p-4">
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Sensor Platform</h3>
                        <p className="text-black mb-4">At the core of our Pet IoT Solutions is the Mainlogix Health and Wellness Ecosystem. This patented, configurable sensor platform allows for non-invasive and autonomous collection of clinically validated vital signs and other critical health metrics. It supports integration with various on-body, in-body, or near-body sensors, making it a versatile Internet of Things (IoT) solution.</p>
                    </div> 
                </div>
            </div>
        </div>

        <div className=" pb-5 pt-10">
            <div>
                <h1>Key Metrics Monitored</h1>
                <p className="text-center px-[15%] pb-0">
                Understanding and tracking key metrics is essential for ensuring the health, safety, and well-being of your pets. Our Pet IoT solutions provide real-time monitoring of critical indicators, offering you peace of mind and a deeper connection with your furry companions. 
                </p>
            </div>
        </div>

        <div className=' flex smd:flex-col justify-between py-10 xl:gap-8 mx-[5%]'>

            <div className="overflow-hidden flex flex-col h-full">          
                <div className="flex flex-col items-center justify-between flex-1">
                    
                        <FontAwesomeIcon icon={faGlobe} color='white' className='bg-red-600 rounded-full p-2'/>
                        <h3 className=" smd:text-[17px] text-xl 2xl:text-2xl text-center font-semibold mb-2">Cardiovascular Diagnostics</h3>
                        <p className="text-black text-center mb-4">Heart Rate Variability, 
                            Blood Pressure, Heart Rate
                        </p>
                </div>
                
            </div>

            <div className="overflow-hidden flex flex-col h-full">          
                <div className="flex flex-col items-center justify-between flex-1">
                    
                        <FontAwesomeIcon icon={faGlobe} color='white' className='bg-red-600 rounded-full p-2'/>
                        <h3 className="smd:text-[17px] text-xl 2xl:text-2xl text-center font-semibold mb-2">Activity Metrics</h3>
                        <p className="text-black text-center mb-4">Distance Traveled, Calories Burned, Quality of Rest
                        </p>
                </div>
                
            </div>

            <div className="overflow-hidden flex flex-col h-full">          
                <div className="flex flex-col items-center justify-between flex-1">
                    
                        <FontAwesomeIcon icon={faGlobe} color='white' className='bg-red-600 rounded-full p-2'/>
                        <h3 className="smd:text-[17px] text-xl 2xl:text-2xl text-center font-semibold mb-2">Respiratory Metrics</h3>
                        <p className="text-black text-center mb-4">Respiratory Rate, Blood Oxygen Saturation
                        </p>
                </div>
                
            </div>

            <div className="overflow-hidden flex flex-col h-full">          
                <div className="flex flex-col items-center justify-between flex-1">
                    
                        <FontAwesomeIcon icon={faGlobe} color='white' className='bg-red-600 rounded-full p-2'/>
                        <h3 className="smd:text-[17px] text-xl 2xl:text-2xl text-center font-semibold mb-2">Core Body Temperature</h3>
                        <p className="text-black text-center mb-4">Respiratory Rate, Blood Oxygen Saturation
                        </p>
                </div>
                
            </div>
        </div>



        <div>
            <div className="grid smd:grid-cols-1 grid-cols-2 md:items-start items-center gap-4 smd:mx-3 mx-12 py-5">     
                <div className="flex justify-center overflow-hidden rounded-3xl">
                    <img 
                        src={pet2}
                        className="object-cover" 
                        alt="Our solutions" 
                    />
                </div>
                <div>
                    <div>
                        <div>
                            <h3 className="leading-normal">Intelligent Analytics</h3>
                            <article className="font-roboto smd:px-0 px-[16px] 2xl:text-2xl">
                                Data collected by the sensor platform is transmitted to 
                                the Mainlogix cloud or private network for storage, post-processing, 
                                and cognitive customer analytics. Our innovative algorithms 
                                enable predictive analytics that support earlier and less invasive 
                                interventions, enhancing clinical outcomes. By leveraging advanced 
                                analytics and predictive algorithms, Intelligent Analytics not only 
                                provides valuable insights into health metrics but also enables earlier 
                                and less invasive interventions, ultimately enhancing clinical outcomes 
                                and improving overall health management. 
                            </article>
                            
                            <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
                                <ul className="list-disc px-6 2xl:text-2xl">
                                    {petData.map((item, index) => (
                                        item.analytic.map((pet, subIndex) => (
                                            <li key={`${index}-${subIndex}`}>
                                                <strong>{pet[0]}</strong> {pet[1]}
                                            </li>
                                        ))
                                    ))}
                                </ul>
                            </div>   
                        </div>
                    </div>    
                </div>  
            </div>
        </div>

        <div className="md:py-5 py-10  smd:px-3  px-40 relative flex flex-col justify-center">
            <h2 className="smd:text-[17px] font-poppins font-semibold text-center text-[40px] text-black">
                Accessories
            </h2>
            <p className=" text-center px-[5%] mt-4">
                Discover a range of essential accessories designed to enhance the functionality and convenience of your Pet IoT devices, ensuring seamless integration and improved care for your pets.
            </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 smd:my-3 md:my-5 my-20 mx-[5%]">
            {petAccessoriesData.slice(0, 6).map((iotUseCase, index) => (
                <div key={index} className="rounded-lg 2xl:rounded-3xl overflow-hidden shadow-lg flex flex-col pb-12 h-[21rem] 2xl:h-[35rem] ">
                    <img
                        src={iotUseCase.img}
                        alt="Real Time Operations"
                        className="w-full h-52 2xl:h-96 object-cover"
                    />
                    <div className="flex-1 flex flex-col justify-between gap-1 py-4 px-1">
                        <h3 className="smd:text-[17px] text-xl 2xl:text-2xl text-center font-semibold m-0 leading-tight">{iotUseCase.heading}</h3>
                        <p className="text-black text-center mt-2 leading-normal">{iotUseCase.content}</p>
                    </div>
                </div>
            ))}
        </div>

        <div className="mx-[5%] my-20 bg-white">
            <div className="rounded-2xl overflow-hidden shadow-2xl flex flex-col h-full">
                <div className="flex justify-between items-center p-4">
                    <div>
                        {/* <h3 className="text-xl font-semibold mb-2">Conclusion</h3> */}
                        <p className="text-black mb-4">Our Pet IoT Solutions provide 
                            peace of mind by ensuring your pets are healthy, safe, 
                            and well-monitored. Whether you have one pet or multiple, 
                            our system is designed to give you the best insights and 
                            control over their well-being.

                        </p>
                    </div> 

                    <div >
                        <img 
                            src={pet9}
                            alt='pet IOT solutions'
                        />
                    </div>
                </div>
            </div>
        </div>
       <Footers />
    </div>
  );
}

export default PetIot;
