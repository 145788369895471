import React from "react";
import FollowUs from "./FollowUs";
import Footer1 from "./Footer1";
import Footer2 from "./Footer2";


export default function Footers(){

    return (
        <div>
            <FollowUs />
            <div className="mx-[5%]">
                <Footer1 />
                <Footer2 />
                
            </div>
            
        </div>
    )
}

