import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { productData } from '../data/data1';
import Header from '../components/home/Header';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../pdtDetails.css';
import { noImage } from '../assets';
import { Helmet } from 'react-helmet-async';
import Footers from '../components/home/Footers';

function PdtDetails() {
  const { id } = useParams();
  const product = productData.find(item => item.id === parseInt(id));

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  if (!product) {
    return <p>Product not found!</p>;
  }

  return (
    <div className='bg-white'>

    <Helmet>
      <title>Mainlogix Product Details | In-Depth Insights and Specifications</title>
      <meta name="description" content="Explore detailed information about Mainlogix's cutting-edge products. Our product details page provides comprehensive insights, specifications, and features to help you make informed decisions. From technical specifications to user benefits, get all the information you need about our innovative solutions designed to enhance your lifestyle and meet your needs." />
    </Helmet>


      <Header />
      <div className="container mx-auto p-6">
        <div className='grid grid-cols-2 gap-8 smd:flex flex-col'>
          <div className='rounded-lg overflow-hidden'>
            <Carousel
              showThumbs={true}
              showStatus={false}
              infiniteLoop
              useKeyboardArrows
              autoPlay={false}
              interval={3000}
              stopOnHover
              className="custom-carousel"
            >
              {product.images.map((img, index) => (
                  <div key={index} className='item-image-container rounded-lg'>
                      <img 
                        className="item-image 2xl:object-contain 2xl:w-full"
                        src={img} 
                        alt={`${product.name}-${index}`} 
                         />
                  </div>
              ))}
            </Carousel>
          </div>

          <div>
            <div>
              <h2 className='text-[32px] font-semibold'>{product.name}</h2>
              <p>{product.description1[0]}</p>
              <div className='pt-8 ml-5'>
                <ul className='list-disc'>
                  {product.description2.map((desc, index) => {
                    const [title, dspt] = desc.split(':');
                    return (
                      <li key={index} className='des-gray 2xl:text-2xl'>
                        <span className="font-semibold">{title}</span>: {dspt}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        
      </div>

      <div className='bg-slate-100 py-12  '>
        <div className='bg-white shadow-xl rounded-xl lg:mx-10 smd:p-[5%] p-12'>
          <h2 className="text-2xl 2xl:text-4xl font-semibold mb-2">Benefits</h2>
          <article className='des-gray 2xl:text-2xl'>{product.benefitDes}</article>
        
          <div className='rounded-xl border-gray-200 border-[1px]  '>
            
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center smd:pb-5 smd:flex smd:flex-col-reverse p-0 m-0'>
              {product.benefits[0] && (
                <div>
                  <h2 className='px-10 smd:px-[5%] smd:text-sm font-semibold text-xl 2xl:text-2xl text-left' >{product.benefits[0][0]}</h2>
                  <p className='px-10 smd:px-[5%] text-left'>{product.benefits[0][1]}</p>
                </div>
              )}

              {product.images[1] ? (
                <img 
                  src={product.images[1]} 
                  alt='Product' 
                  className='object-contain w-[100%]'
                  style={{ height: 'auto', maxHeight: '300px', width: '100%' }} />
              ) : (
                product.benefits[0] && (
                  <img 
                    src={noImage} 
                    alt='Default'
                    className='object-contain w-[100%]'
                    style={{ height: 'auto', maxHeight: '300px', width: '100%' }} 
                  />
                )
              )}
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center p-0 m-0 smd:pb-5'>

              {product.images[2] ? (
                <img 
                  src={product.images[2]} 
                  alt='Product' 
                  className='object-cover w-[100%]'
                  style={{ height: 'auto', maxHeight: '300px', width: '100%' }} />
              ) : (
                product.benefits[1] && (
                  <img 
                    src={noImage} 
                    alt='Default'
                    className='object-cover w-[100%]'
                    style={{ height: 'auto', maxHeight: '300px', width: '100%' }} 
                  />
                )
              )}

              {product.benefits[1] && (
                <div>
                  <h2 className='px-10 smd:px-[5%] smd:text-sm font-semibold text-xl 2xl:text-2xl text-left' >{product.benefits[1][0]}</h2>
                  <p className='px-10 smd:px-[5%] text-left'>{product.benefits[1][1]}</p>
                </div>
              )}

            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center p-0 m-0 smd:flex smd:flex-col-reverse smd:pb-5'>
              {product.benefits[2] && (
                <div>
                  <h2 className='px-10 smd:px-[5%] smd:text-sm font-semibold text-xl 2xl:text-2xl text-left' >{product.benefits[2][0]}</h2>
                  <p className='px-10 smd:px-[5%] text-left'>{product.benefits[2][1]}</p>
                </div>
              )}

              {product.images[3] ? (
                <img 
                  src={product.images[3]} 
                  alt='Product' 
                  className='object-cover w-[100%]'
                  style={{ height: 'auto', maxHeight: '300px', width: '100%' }} />
              ) : (
                product.benefits[2] && (
                  <img 
                    src={noImage} 
                    alt='Default'
                    className='object-contain w-[100%]'
                    style={{ height: 'auto', maxHeight: '300px', width: '100%' }} 
                  />
                )
              )}
            </div>
            
          </div>
        </div>
      </div>
      <Footers />
    </div>
  );
}

export default PdtDetails;
