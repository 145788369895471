import React from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { existingSolution, introBg, ourSolutions, } from "../assets";
import { existingSolutionsData} from "../data/data3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from 'react-helmet-async';


export default function ExistingSolutions(){

    return (
        <div className="bg-slate-100" >

            <Helmet>
                <title>Mainlogix Existing Solutions | Proven Technology for Diverse Needs</title>
                <meta name="description" content="Explore Mainlogix's range of existing solutions that address various industry needs with proven technology. Our solutions span across IoT, automation, smart home systems, and more, offering reliable and efficient tools designed to enhance performance and drive innovation. Learn about our successful implementations and discover how our existing solutions can provide value and solve challenges in your business or personal applications." />
            </Helmet>


            <Header />
                <div className="relative w-full ">
                    <div
                        className="absolute inset-0 bg-cover bg-center"
                        style={{ backgroundImage: `url(${introBg})` }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                    </div>
                    
                    <div className="page-title relative grid smd:grid-cols-1 grid-cols-2 justify-center items-center smd:px-3 px-[5%] smd:py-10 py-20 text-center">
                            <div>
                                <h2 className="title-text font-poppins font-semibold smd:text-center text-left text-[55px] text-white">
                                    Our Solutions
                                </h2>
                                <p className="text-white smd:text-center text-left mt-4">
                                    Explore our proven IoT and smart home solutions that 
                                    have already transformed industries and homes, enhancing 
                                    efficiency, security, and user experience across various 
                                    applications.
                                </p>
                            </div>
                            <div className="smd:hidden">
                                <img 
                                    src={ourSolutions} 
                                    alt="Existing Solutions"
                                    className="w-[50%] float-right" 
                                />
                            </div>
                        
                    </div>
                </div>   
                
                {/* Geographic Layers */}

                <div className="bg-white py-10">
                    <div>
                        <h1>Features</h1>
                        <p className="text-center px-[15%] pb-0">
                            Discover the powerful features that make our IoT 
                            solutions stand out, providing unparalleled functionality 
                            and ease of use for diverse applications.
                        </p>
                        <div className="grid smd:grid-cols-1 md:grid-cols-1 grid-cols-2 gap-4 smd:mx-3 mx-12 py-5">
                            <div className="flex justify-center overflow-hidden rounded-3xl bg-gray-200 md:mx-[20%]">
                                <img 
                                    src={existingSolution}
                                    className="object-cover" 
                                    alt="Our solutions" 
                                />
                            </div>
                            <div>
                                <div>
                                    {existingSolutionsData.map((item, index) => (
                                        <div key={index} >
                                            <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
                                                <FontAwesomeIcon icon={item.icon} color="white" className="p-2 mt-2 bg-red-700 rounded-full" />
                                                <div>
                                                    <h4 className="2xl:text-2xl smd:leading-normal">
                                                        {item.title}
                                                    </h4>
                                                    <p>
                                                        {item.des}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    ))}

                                </div>
                               
                            </div>

                        </div>
                        


            
                    </div>

                </div>

            <Footers />
        </div>
    )
}


