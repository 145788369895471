import React from "react";
import { ourSolutions } from "../../assets";
import { solutionsData } from "../../data/data2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { existingSolutionsData } from "../../data/data3";
import { Link } from "react-router-dom";

export default function OurSolutions(){
    return (
        <div className="bg-white mt-10 smd:mx-3 mx-9">
            <div>
                <h1>Our Solutions</h1>
                <p className="text-center smd:px-3 px-[30%] pb-0">
                    Our solutions drive transformative innovation, combining cutting-edge IoT technology with advanced automation to deliver unparalleled efficiency, real-time insights, and sustainable growth.
                </p>
                <div className="grid place-items-center smd:grid-cols-1 md:grid-cols-1 grid-cols-2 gap-4 items-center justify-center smd:mx-3 mx-12 py-5">
                    <div lassName="overflow-hidden rounded-3xl bg-gray-200 md:mx-[20%]">
                        <img 
                            src={ourSolutions}
                            className="object-cover" 
                            alt="Our solutions" 
                        />
                    </div>
                    <div>
                        <div>
                            {existingSolutionsData.map((item, index) => (
                                <div key={index}>
                                    <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
                                        <FontAwesomeIcon icon={item.icon} color="white" className="p-2 mt-2 bg-red-700 rounded-full" />
                                        <div>
                                            <h4 className="2xl:text-2xl smd:leading-normal">
                                                {item.title}
                                            </h4>
                                            <p>
                                                {item.des}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            ))}

                        </div>
                        <Link to="/existing-solutions" >
                            <button  className='button-m text-center'>Learn More</button>
                        </Link>
                    </div>

                </div>
                


    
            </div>

        </div>
    )
}
