import React from "react";
import { footerData } from "../../data/data3";

export default function Footer1() {
    return (
        <div className="mt-12">
            <h2 className="font-poppins smd:text-[12px] text-[14px] 2xl:text-3xl text-red-500 font-semibold">IOT USE CASES</h2>
            <div className="grid smd:grid-cols-1 pt-3 grid-cols-5 gap-4">
                {footerData.map((item, index) => (
                    <div key={index}>
                        <h2 className="font-poppins smd:text-[12px] text-[14px] 2xl:text-3xl font-semibold">{item.heading}</h2>
                        <ul className="list-none">
                            {item.content.map((contentItem, i) => (
                                <li key={i} className="font-roboto smd:text-[12px] text-[14px] 2xl:text-3xl text-black mt-1">
                                    {contentItem}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
}
