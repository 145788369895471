// src/components/ServiceCard.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

function ServiceCard({ id, title, des, link, image }) {
  const navigate = useNavigate();

  const handleClick = () => {
    // navigate(link);
    navigate(link, { state: { clickedCardId: id } });
  };

  return (
    <div 
      className="relative h-[300px] 2xl:h-[600px] 2xl:rounded-3xl rounded-lg overflow-hidden shadow-lg cursor-pointer hover:shadow-xl transition-shadow duration-300" 
      onClick={handleClick}
    >
      {/* Image that fills the card */}
      <img 
        src={image} 
        alt={title} 
        className="w-full h-full object-cover" 
      />
      
      {/* Gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-75"></div>

      {/* Title and description content */}
      <div className="absolute bottom-0 left-0 right-0 p-4 text-white mb-3">
        <h2 className="lg:text-lg 2xl:text-2xl font-bold mb-1">{title}</h2>
        <p className="font-roboto text-white">{des}</p>
      </div>
    </div>
  );
}

export default ServiceCard;
