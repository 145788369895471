import React from 'react'
import { visionOne, visionTwo } from '../../assets'

const Mission = () => {
  return (
    <div >
      
      <div >
        <div className="py-10 px-40 relative flex flex-col justify-center">
            <h2 className="font-poppins font-semibold text-center text-[40px] text-black relative after:content-[''] after:block after:w-12 after:h-[2px] after:bg-red-500 after:absolute after:left-1/2 after:bottom-0 after:transform after:-translate-x-1/2">
              Our Mission & Vision
            </h2>
        </div>
        <div className='grid grid-cols-2'>
          <div >
              <p className='text-black'>
                <strong>Mission:</strong> Empowering Lives Through Seamless Integration
              </p>
          
              <p className='text-black'>
              At Mainlogix Technology, our mission is to forge a connected future where cutting-edge technology integrates seamlessly across domains, fostering interoperability, privacy, and empowerment.  We prioritize a user-centric approach, enhancing smart home management, healthcare accessibility, and seniors' autonomy. Our aim is to build smart communities that honor users’ privacy and elevate living standards. 
              </p>
              <br />
              <p className='text-black'>
                <strong>Vision: </strong>
                An Innovative IoT Future
              </p>
              <p className='text-black'>
                Mainlogix envisions a future where interoperability and IoT innovations connect various aspects of life, from healthcare to home automation. We aspire to empower individuals within smart communities, ensuring enhanced well-being, empowerment, and privacy through our cutting-edge solutions.
              </p>
          </div>
        
          <div className='relative flex justify-center items-end ' >
            <img 
              src={visionOne} 
               
              alt="vision" 
              className='h-[70%]'
            />
            <img 
              src={visionTwo}
              alt="vision" 
              className='absolute h-[70%] left-[50%] top-0 '
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mission
