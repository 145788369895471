import * as pic from '../assets/index';
import { faArrowRight, faGlobe, faLink, faPlusCircle, faCompass } from '@fortawesome/free-solid-svg-icons';



export const solutionsData = [
    {
        id: 1,
        title: "IoT & Vehicle Tracking Web Platform",
        des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation",
        link: "/",
        icon: faGlobe
    },
    {
        id: 2,
        title: "Predefined Routes",
        des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation",
        link: "/",
        icon: faCompass
    },
    {
        id: 3,
        title: "Mobile Navigation with 'Turn by turn' Instructions",
        des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation",
        link: "/",
        icon: faArrowRight
    }
]

export const partnersData = [
    {
        title: "AWS",
        image: `${pic.partnerAWS}`,
    },
    {
        title: "Alexa",
        image: `${pic.partnerAlexa}`,
    },
    {
        title: "Google Smart",
        image: `${pic.partnerGSmart}`,
    },
    {
        title: "Home Kit",
        image: `${pic.partnerHomekit}`,
    },
    {
        title: "NFC",
        image: `${pic.partnerNFC}`,
    },
    {
        title: "Smart Things",
        image: `${pic.partnerSmartthings}`,
    },
    {
        title: "ZigBee",
        image: `${pic.partnerZigbee2}`,
    },
    {
        title: "ZWave",
        image: `${pic.partnerZwave}`,
    },
   
]


export const blogData = [
    {
      id: 1,
      title: "Harnessing IoT in Manufacturing",
      description: "Dive into the strategies and technologies driving Industry 4.0, and learn how smart manufacturing solutions are optimizing processes, increasing productivity, and paving the way for the future of industry",
      author: "Saurabh Shrivastava",
      date: "1 August 2024",
      image: `${pic.blog1}`,
    },
    {
      id: 2,
      title: "Unlock the Future",
      description: "Discover how IoT and automation are transforming smart cities, creating unparalleled levels of efficiency and connectivity that are shaping the urban landscapes of tomorrow.",
      author: "Ritesh Sutaria",
      date: "25 July 2024",
      image: `${pic.blog2}`,
    },
    {
      id: 3,
      title: "The Power of IoT-Driven",
      description: "Explore how integrating IoT with predictive analytics can drastically reduce downtime, enhance asset performance, and revolutionize maintenance strategies with intelligent automation.",
      author: "Mike Johnson",
      date: "18 July 2024",
      image: `${pic.blog3}`,
    },
    
  ]


  export const faqData = [
    {
      question: "What is your return policy for IoT products?",
      answer: "You can return any IoT product within 30 days of purchase if it is in its original condition and packaging. Please contact our support team for return authorization and instructions.",
    },
    {
      question: "How do I track my IoT device order?",
      answer: "Once your IoT device order has shipped, you will receive a confirmation email with a tracking number and a link to track your shipment in real-time.",
    },
    {
      question: "Can I purchase your IoT solutions internationally?",
      answer: "Yes, Mainlogix Limited offers international shipping for our IoT solutions and products. Please note that additional shipping fees and customs duties may apply depending on your location.",
    },
    {
      question: "How can I contact Mainlogix customer service?",
      answer: "You can reach our customer service team via email at info@mainlogix.ca or call us at +1-902-488-1641 for any inquiries related to our IoT and automation services or products.",
    },
    {
      question: "Do you offer gift cards for IoT and automation products?",
      answer: "Yes, we offer both physical and electronic gift cards that can be redeemed for any of our IoT and automation products and services. Available in various denominations.",
    },
    {
      question: "What payment methods do you accept for IoT services?",
      answer: "We accept all major credit cards, PayPal, Apple Pay, and bank transfers for purchasing our IoT services and products.",
    },
    {
      question: "Do you provide custom IoT solutions?",
      answer: "Yes, we specialize in creating custom IoT solutions tailored to meet the unique needs of your business, ensuring optimal performance and seamless integration with existing systems.",
    },
    {
      question: "What industries do you serve with your IoT solutions?",
      answer: "Mainlogix Limited serves a wide range of industries, including manufacturing, healthcare, agriculture, logistics, smart cities, and more. Our IoT solutions are designed to enhance efficiency and productivity across various sectors.",
    },
    {
      question: "How secure are your IoT products and services?",
      answer: "Security is a top priority at Mainlogix. Our IoT products and services are built with robust security protocols, including data encryption, secure authentication, and regular security updates to protect your data and devices from threats.",
    },
    {
      question: "Can you help with IoT device integration into existing systems?",
      answer: "Yes, our team of experts can assist with integrating IoT devices into your existing infrastructure, ensuring seamless communication and interoperability between new and legacy systems.",
    },
    {
      question: "What is your support policy for IoT products?",
      answer: "We provide comprehensive support for all our IoT products, including troubleshooting, software updates, and maintenance. Our support team is available to assist you with any technical issues you may encounter.",
    },
    {
      question: "How do I get started with an IoT project?",
      answer: "Getting started with an IoT project is easy with Mainlogix. Contact us to schedule a consultation, and our experts will help you define your project requirements, choose the right technology, and develop a tailored IoT solution that meets your needs.",
    },
    {
      question: "Do you offer training on IoT and automation systems?",
      answer: "Yes, we offer training programs designed to help your team understand and maximize the potential of IoT and automation systems, ensuring successful adoption and implementation.",
    },
    {
      question: "What is IT/OT convergence, and how can it benefit my business?",
      answer: "IT/OT convergence is the integration of information technology (IT) systems with operational technology (OT) systems, enabling better data sharing, improved efficiency, and enhanced decision-making across your business. We offer solutions to help you achieve this integration seamlessly.",
    },
    {
      question: "Can I upgrade my existing IoT system with new features?",
      answer: "Yes, we offer upgrade services for existing IoT systems, allowing you to add new features, enhance functionality, and ensure your system stays up-to-date with the latest technology advancements.",
    },
    {
      question: "What is your approach to IoT data privacy?",
      answer: "We take IoT data privacy very seriously. Our solutions are designed with privacy in mind, ensuring that data collected by IoT devices is handled securely, with compliance to data protection regulations and best practices.",
    },
    {
      question: "Do you offer IoT consulting services?",
      answer: "Yes, we provide expert IoT consulting services to help you navigate the complexities of IoT implementation, from initial strategy development to deployment and ongoing management.",
    },
    {
      question: "What is the typical timeline for an IoT project?",
      answer: "The timeline for an IoT project can vary depending on the scope and complexity of the solution. During our initial consultation, we will provide you with an estimated timeline based on your specific project requirements.",
    },
    {
      question: "How do IoT managed services work?",
      answer: "Our IoT managed services provide ongoing management, monitoring, and maintenance of your IoT systems, ensuring optimal performance, security, and scalability. We handle the technical details so you can focus on your core business operations.",
    },
    {
      question: "Do you offer IoT machine learning services?",
      answer: "Yes, we offer IoT machine learning services that allow you to leverage advanced algorithms for predictive analytics, automated decision-making, and intelligent automation, enhancing the capabilities of your IoT solutions.",
    }
  ];

  

  export const techToolsFaqData = [
    // Communication Protocols
    {
      question: "Which communication protocols do you support?",
      answer: "We support a wide range of communication protocols including LoRa, WiFi, Zigbee, Z-Wave, and Matter, ensuring robust and flexible connectivity across various IoT environments.",
    },
    {
      question: "What is LoRa and how is it used in IoT applications?",
      answer: "LoRa (Long Range) is a wireless communication protocol designed for long-range, low-power IoT applications. It's ideal for remote sensors and devices that need to transmit data over long distances with minimal power consumption.",
    },
    {
      question: "How does Zigbee compare to Z-Wave for home automation?",
      answer: "Zigbee and Z-Wave are both popular for home automation. Zigbee offers a larger network capacity and higher data rates, while Z-Wave is known for its interoperability and reliable performance in smaller networks.",
    },
    {
      question: "What advantages does the Matter protocol offer for smart devices?",
      answer: "Matter provides a unified standard for smart devices, enhancing interoperability and security. It simplifies device integration across different ecosystems, offering a seamless user experience and reducing compatibility issues.",
    },
    {
      question: "How does WiFi contribute to IoT solutions?",
      answer: "WiFi offers high-speed data transfer and broad coverage, making it suitable for applications requiring real-time data communication and internet access, such as smart home devices and industrial IoT systems.",
    },
    {
      question: "What are the main use cases for LoRa?",
      answer: "LoRa is ideal for applications like smart agriculture, remote monitoring, and asset tracking where long-range communication and low power consumption are essential.",
    },
    {
      question: "Can Zigbee be used in industrial settings?",
      answer: "Yes, Zigbee can be used in industrial settings for applications like automation and monitoring, where its low power consumption and reliable mesh networking are beneficial.",
    },
    {
      question: "What security features does the Matter protocol include?",
      answer: "Matter includes robust security features such as end-to-end encryption and secure device onboarding, ensuring that connected devices are protected against unauthorized access and data breaches.",
    },
    {
      question: "How does Z-Wave handle device interoperability?",
      answer: "Z-Wave handles device interoperability through its certification program, which ensures that all certified devices can communicate seamlessly within a Z-Wave network, regardless of the manufacturer.",
    },
    {
      question: "What are the benefits of using WiFi for IoT devices?",
      answer: "WiFi offers high bandwidth, extensive range, and compatibility with existing networks, making it a good choice for applications requiring substantial data transfer and internet connectivity.",
    },

    // Supported Architectures
    {
      question: "What processor architectures are supported in your solutions?",
      answer: "We support a variety of architectures including MIPS, ARM Cortex-M/Cortex-A, Renesas RXV1/RXV2, MicroBlaze, and Coldfire v2, catering to diverse IoT applications and performance needs.",
    },
    {
      question: "How does ARM Cortex enhance the performance of IoT devices?",
      answer: "ARM Cortex processors are known for their high performance, low power consumption, and scalability. They are well-suited for a range of IoT applications, from low-power sensors to high-performance embedded systems.",
    },
    {
      question: "What are the benefits of using MIPS architecture?",
      answer: "MIPS architecture offers a balance of performance and power efficiency, with a range of processors suitable for various applications from consumer electronics to industrial systems.",
    },
    {
      question: "How does the MicroBlaze architecture support FPGA-based designs?",
      answer: "MicroBlaze is a 32-bit RISC processor core designed for Xilinx FPGAs, providing a flexible, customizable solution for implementing high-performance embedded systems in FPGA environments.",
    },
    {
      question: "What is the Renesas RXV1/RXV2 architecture known for?",
      answer: "The Renesas RXV1/RXV2 architecture is known for its high performance, low power consumption, and integrated security features, making it ideal for industrial and automotive applications.",
    },
    {
      question: "Why choose Coldfire v2 architecture for your project?",
      answer: "Coldfire v2 architecture provides a robust and cost-effective solution for embedded systems with its efficient instruction set and performance characteristics, suitable for a wide range of applications.",
    },
    {
      question: "What are the advantages of using ARM Cortex-A processors?",
      answer: "ARM Cortex-A processors offer high processing power and advanced features like multimedia support, making them ideal for complex applications requiring substantial computational resources.",
    },
    {
      question: "Can your solutions be adapted for custom architectures?",
      answer: "Yes, our team has the expertise to adapt and optimize solutions for custom and specialized architectures, ensuring maximum performance and compatibility with your unique requirements.",
    },
    {
      question: "How do you ensure compatibility with different processor architectures?",
      answer: "We use cross-platform development tools and adhere to industry standards to ensure compatibility with various processor architectures, allowing for seamless integration and deployment.",
    },
    {
      question: "What considerations are there when choosing an architecture for IoT?",
      answer: "Considerations include processing power, power consumption, memory requirements, and peripheral support. We help you select an architecture that meets your project's specific needs and constraints.",
    },

    // Supported MCUs
    {
      question: "Which microcontrollers (MCUs) do you work with?",
      answer: "We work with a wide range of MCUs including those from Microchip/Atmel, Freescale/NXP, ARM, Renesas, ST, TI, Microsemi, Xilinx, and Spansion/Fujitsu, providing flexibility in design and functionality.",
    },
    {
      question: "How do you choose the right MCU for a project?",
      answer: "Selecting the right MCU involves evaluating factors like processing power, memory, peripheral support, and power requirements. We assist in choosing an MCU that aligns with your project’s specific needs and objectives.",
    },
    {
      question: "What is the significance of using Microchip/Atmel MCUs?",
      answer: "Microchip/Atmel MCUs are known for their reliability and extensive ecosystem, providing a wide range of options for different applications, from simple sensors to complex control systems.",
    },
    {
      question: "How does the choice of MCU affect the performance of an IoT device?",
      answer: "The choice of MCU impacts the device’s processing speed, power consumption, and ability to handle specific tasks. We help you select an MCU that optimizes performance based on your application’s requirements.",
    },
    {
      question: "Can you support integration with Freescale/NXP MCUs?",
      answer: "Yes, we provide support for integrating Freescale/NXP MCUs into your projects, offering solutions tailored to meet the specific needs and performance criteria of your applications.",
    },
    {
      question: "What are the benefits of using ARM MCUs in IoT solutions?",
      answer: "ARM MCUs offer high performance, low power consumption, and extensive support for development tools, making them suitable for a wide range of IoT applications requiring efficiency and scalability.",
    },
    {
      question: "How do Renesas MCUs fit into industrial IoT applications?",
      answer: "Renesas MCUs are designed for industrial applications with features like high reliability, robustness, and support for real-time processing, making them ideal for demanding industrial IoT environments.",
    },
    {
      question: "What are the advantages of using Xilinx MCUs?",
      answer: "Xilinx MCUs provide flexibility through FPGA integration, allowing for customizable hardware and high-performance processing capabilities, suitable for complex IoT applications and custom solutions.",
    },
    {
      question: "Do you support legacy MCUs in your solutions?",
      answer: "Yes, we support both modern and legacy MCUs, ensuring that existing systems can be maintained or upgraded with the latest IoT technologies and enhancements.",
    },
    {
      question: "How do you handle MCU compatibility issues?",
      answer: "We address MCU compatibility issues by using standardized interfaces and adapting our solutions to ensure seamless integration and performance across different microcontroller platforms.",
    },

    // Supported IDEs
    {
      question: "What Integrated Development Environments (IDEs) do you support?",
      answer: "We support a wide range of IDEs including IAR, HEW/e2studio, Sourcery CodeBench, MPLAB/MPLABX, Code Composer Studio, System Workbench, ThunderBench KDS, Soft Console, Xilinx SDK, and Keil MDK.",
    },
    {
      question: "How does using different IDEs benefit IoT development?",
      answer: "Different IDEs offer various features and tools that can enhance the development process. We provide support for a range of IDEs to ensure you can choose the one that best fits your project’s needs and workflow.",
    },
    {
      question: "What are the features of IAR Embedded Workbench?",
      answer: "IAR Embedded Workbench offers powerful debugging, code optimization, and comprehensive support for various MCUs, making it a preferred choice for developing reliable and high-performance embedded applications.",
    },
    {
      question: "Why choose MPLABX for your development projects?",
      answer: "MPLABX provides an integrated environment with strong support for Microchip MCUs, including advanced debugging, code analysis tools, and a customizable user interface to streamline development.",
    },
    {
      question: "What advantages does Code Composer Studio offer?",
      answer: "Code Composer Studio provides an integrated development environment with extensive debugging capabilities, support for TI processors, and tools for optimizing code and enhancing performance in embedded systems.",
    },
    {
      question: "How does Sourcery CodeBench facilitate embedded development?",
      answer: "Sourcery CodeBench offers a comprehensive set of development tools including advanced debugging, code optimization, and multi-core support, which helps streamline development and improve code quality.",
    },
    {
      question: "What is System Workbench used for?",
      answer: "System Workbench is an IDE for developing applications on STM32 microcontrollers, offering features like advanced debugging, code analysis, and project management tools tailored for STM32 development.",
    },
    {
      question: "How does Keil MDK support embedded development?",
      answer: "Keil MDK provides a complete development environment with robust debugging, code optimization, and extensive support for ARM Cortex-M processors, helping developers build and maintain efficient embedded applications.",
    },
    {
      question: "What is the role of Xilinx SDK in FPGA development?",
      answer: "Xilinx SDK provides a comprehensive development environment for designing, debugging, and optimizing applications on Xilinx FPGAs, enabling efficient implementation of complex hardware and software designs.",
    },
    {
      question: "Can you assist with setting up and configuring IDEs?",
      answer: "Absolutely, we offer support for setting up and configuring your chosen IDE, ensuring that you have the right environment and tools to develop, test, and deploy your IoT solutions efficiently.",
    },
];
