import React from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { introBg } from "../assets";
import { IotDevelopmentServicesData } from "../data/data3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from 'react-helmet-async';


export default function IotDevelopmentServices(){

    return (
        <div className="bg-white" >
            <Helmet>
                <title>Mainlogix IoT Development Services | Innovative Solutions for Connected Systems</title>
                <meta name="description" content="Discover Mainlogix’s IoT development services designed to empower your business with smart, connected solutions. Our expert team offers end-to-end services, including IoT architecture design, device integration, software development, and data analytics. Whether you need scalable industrial IoT solutions, home automation systems, or custom smart devices, we provide tailored solutions to meet your specific requirements. Enhance operational efficiency and connectivity with our comprehensive IoT development services." />
            </Helmet>

            <Header />

            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white">
                        IoT Development Services
                    </h2>
                    <p className="text-center text-white mt-4">
                        Seamlessly Connect, Innovate, and Grow with Mainlogix's Cutting-Edge IoT Development Services
                    </p>
                </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 py-14 gap-6 mx-[5%]">
                {IotDevelopmentServicesData.slice(0, 6).map((expert, index) => (
                <div key={index} className="rounded-lg overflow-hidden shadow-lg flex flex-col h-full">
                    <div className="flex justify-center p-4 items-start">
                        <FontAwesomeIcon icon={expert.icon} color="white" className="w-4  p-2 mr-2 bg-red-700 rounded-full" />
                        <div className="flex flex-col justify-between flex-1 ">
                            
                            <div>
                                <h3 className="text-xl 2xl:text-2xl font-semibold mb-2">{expert.heading}</h3>
                                <p className="text-black mb-4">{expert.content}</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
                ))}
            </div>
            <Footers />
        </div>
    )
}


