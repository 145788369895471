import React from "react";
import '../../whyChooseUs.css'; // Import a custom CSS file for styling
import { cardsData } from "../../data/data1";

export default function WhyChooseUs() {
  

    return (
        <div className="why-choose-us-container">
          <h1 className=" my-5 ">WHY CHOOSE US</h1>
          <div className="cards-container smd:grid-cols-1 grid-cols-3 gap-6">
            {cardsData.map((card, index) => (
              <div key={index} className="card w-[100%] h-40">
                <div className="card-inner">
                  <div className="card-front " style={{ backgroundImage: `url(${card.image})`, backgroundRepeat: "no-repeat",  }}>
                    <h2 className="font-poppins font-semibold z-10">{card.title}</h2>
                  </div>
                  <div className="card-back bg-white">
                    <p className="card-description">{card.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
    );
}
