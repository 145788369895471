import React from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { arrowRight, faq, introBg, reportingFeatures } from "../assets";
import { generalFeaturesData, geographicLayersData, realTimeOPerationGFData, reportingFeaturesData } from "../data/data3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from 'react-helmet-async';


export default function GeneralFeatures(){

    return (
        <div className="bg-slate-100" >

        <Helmet>
            <title>Mainlogix General Features | Advanced Solutions for Modern Needs</title>
            <meta name="description" content="Explore the general features offered by Mainlogix, showcasing our cutting-edge solutions designed to enhance functionality and efficiency across various applications. From robust IoT connectivity and smart automation to seamless integrations and user-friendly interfaces, discover how our advanced features can transform your experience and meet your specific needs. Learn about the key benefits and technological advancements that set Mainlogix apart in delivering high-performance, reliable, and innovative solutions." />
        </Helmet>


            <Header />

            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white">
                        General Features
                    </h2>
                    <p className="text-center text-white mt-4">
                        Explore the key features that drive the effectiveness and efficiency of our IoT solutions, offering robust connectivity, real-time insights, and comprehensive management for various applications.
                    </p>
                </div>
            </div>

            {/* Connected Objects */}

            <div className="bg-white">
                <div className="page-title py-10  px-40 relative flex flex-col justify-center">
                    <h2 className="title-text font-poppins font-semibold text-center text-[40px] text-black">
                        Connected Objects
                    </h2>
                    <p className=" text-center px-[5%] mt-4">
                        Connected objects form the backbone of IoT ecosystems, enabling seamless communication and control over a wide range of devices and systems.
                    </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 pb-[6%] mx-[5%]">
                    {generalFeaturesData.slice(0, 4).map((feature, index) => (
                    <div key={index} className="rounded-lg overflow-hidden shadow-lg flex flex-col h-full">
                        
                        <div className="flex flex-col items-center justify-between flex-1 p-4">
                            
                            
                                <FontAwesomeIcon icon={feature.icon} color="white" className="m-4 p-2 bg-red-700 rounded-full" />
                                <h3 className="text-xl 2xl:text-2xl text-center font-semibold mb-2">{feature.heading}</h3>
                                <p className="text-black text-center mb-4">{feature.content}</p>
                            
                            
                        </div>
                    </div>
                    ))}
                </div>

            </div>

                {/* Real Time Operations */}

                <div className="page-title py-10 px-40 relative flex flex-col justify-center">
                    <h2 className="title-text font-poppins font-semibold text-center text-[40px] text-black">
                        Real Time Operations
                    </h2>
                    <p className=" text-center px-[5%] mt-4">
                        Real-time operations empower organizations to respond instantly to changes, ensuring efficient management and optimization of resources and processes.
                    </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:h-[23rem] 2xl:h-[38rem] mb-20 gap-6 mx-[5%]">
                    {realTimeOPerationGFData.slice(0, 3).map((rto, index) => (
                        <div key={index} className="rounded-lg 2xl:rounded-2xl overflow-hidden shadow-lg flex flex-col h-full">
                            <img
                                src={rto.img}
                                alt="Real Time Operations"
                                className="w-full h-52 2xl:h-96 object-cover"
                            />
                            <div className="flex-1 flex flex-col justify-between p-4">
                                <h3 className="text-xl 2xl:text-2xl text-center font-semibold m-0 leading-tight">{rto.heading}</h3>
                                <p className="text-black text-center mt-2 pb-4 leading-normal">{rto.content}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Geographic Layers */}

                <div className="bg-white smd:py-0 py-10 mt-10">
                    <div>
                        <h1>Geographic Layers</h1>
                        <p className="text-center smd:px-3 px-[15%] pb-0">
                            Geographic layers provide a detailed spatial context, enabling precise mapping, analysis, and management of assets and operations
                        </p>
                        <div className="grid smd:grid-cols-1 grid-cols-2 gap-4 smd:mx-3 mx-12 py-5 ">
                            <div className="flex justify-center overflow-hidden rounded-3xl bg-white">
                                <img 
                                    src={faq}
                                    className="object-cover 2xl:rounded-3xl" 
                                    alt="Our solutions" 
                                />
                            </div>
                            <div>
                                <div>
                                    {geographicLayersData.map((item, index) => (
                                        <div key={index} >
                                            <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
                                                <FontAwesomeIcon icon={item.icon} color="white" className="p-2 mt-2 2xl:mt-0 bg-red-700 rounded-full" />
                                                <div>
                                                    <h4 className="leading-normal 2xl:text-2xl">
                                                        {item.title}
                                                    </h4>
                                                    <p>
                                                        {item.des}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* Reporting Features */}

                <div className="bg-white pb-5 smd:pt-0 pt-10">
                    <div>
                        <h1>Reporting Features</h1>
                        <p className="text-center px-[15%] pb-0">
                            Stay informed and proactive with comprehensive reporting features, designed to deliver actionable insights through alerts, notifications, and detailed dashboards.
                        </p>
                        <div className="grid smd:grid-cols-1  grid-cols-2 gap-4 smd:mx-3 smd:flex smd:flex-col-reverse mx-12 py-5">
                            
                            <div>
                                <div>
                                    {reportingFeaturesData.map((item, index) => (
                                        <div key={index} >
                                            <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
                                                <FontAwesomeIcon icon={item.icon} color="white" className="p-2 mt-2 2xl:mt-0 bg-red-700 rounded-full" />
                                                <div>
                                                    <h4 className="2xl:text-2xl">
                                                        {item.title}
                                                    </h4>
                                                    <p>
                                                        {item.des}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    ))}

                                </div>
                               
                            </div>

                            

                            <div className="flex justify-center overflow-hidden rounded-3xl bg-gray-200">
                                <img 
                                    src={reportingFeatures}
                                    className="object-cover" 
                                    alt="Our solutions" 
                                />
                            </div>

                        </div>
                    </div>
                </div>

                {/* Public Work Features */}
                <div className=" pb-5 pt-10">
                    <div>
                        <h1>Public Work Features</h1>
                        <p className="text-center px-[15%] pb-0">
                            Efficiently manage public works with advanced features tailored to streamline route planning, asset tracking, and operational status monitoring.
                        </p>
                    </div>
                </div>
                <div className="w-full overflow-x-scroll">
                    <div className="grid smd:grid-cols-1 grid-cols-[1fr_0.2fr_1fr_0.2fr_1fr] justify-center py-4 mx-[5%]">
                        
                        <div className="overflow-hidden flex flex-col h-full">
                            
                            <div className="flex flex-col items-center justify-between flex-1 smd:w-[100%]">
                                
                                
                                    <p className="font-poppins text-[20px] text-white m-4 py-2 px-4 2xl:px-6 2xl:py-4 2xl:text-2xl bg-red-700 rounded-full">1</p>
                                    <h3 className="text-xl 2xl:text-2xl text-center font-semibold mb-2">Routes Management</h3>
                                    <p className="text-black text-center mb-4">
                                        Optimize public works routes for efficiency and effectiveness, ensuring timely completion of tasks and better resource allocation.
                                    </p>
                            </div>
                            
                        </div>
                        
                        <div>
                            <img 
                                src={arrowRight}
                                alt="pwf"
                                className="pt-1" 
                            />
                        </div>
                                
                        <div className="overflow-hidden flex flex-col h-full">
                            
                            <div className="flex flex-col items-center justify-between flex-1">
                                
                                    <p className="font-poppins text-[20px] text-white m-4 py-2 px-3 2xl:px-6 2xl:py-4 2xl:text-2xl bg-red-700 rounded-full">2</p>
                                    <h3 className="text-xl 2xl:text-2xl text-center font-semibold mb-2">Tracking</h3>
                                    <p className="text-black text-center mb-4">
                                        Monitor the location and status of public works assets in real-time, enhancing accountability and operational efficiency.
                                    </p>
                            </div>
                            
                        </div>

                        <div>
                            <img 
                                src={arrowRight}
                                alt="pwf"
                                className="pt-1"
                            />
                        </div>

                        <div className="overflow-hidden flex flex-col h-full">
                            
                            <div className="flex flex-col items-center justify-between flex-1">
                                    <p className="font-poppins text-[20px] text-white m-4 py-2 px-3 2xl:px-6 2xl:py-4 2xl:text-2xl bg-red-700 rounded-full">3</p>
                                    <h3 className="text-xl 2xl:text-2xl text-center font-semibold mb-2">Operations Status</h3>
                                    <p className="text-black text-center mb-4">
                                        Stay informed on the status of public works operations, enabling proactive management and timely intervention to address issues.
                                    </p>
                            </div>
                            
                            </div>
                    </div>
                        
                </div>

                    

            

            <Footers />
        </div>
    )
}


