import React from 'react'
import { achievement1, achievement2 } from '../../assets'

const Awards = () => {
  return (
    <div >
      <div className='pb-10'  >
        <div className="py-10 px-40 relative flex flex-col justify-center">
            <h2 className="font-poppins font-semibold text-center text-[40px] text-black relative after:content-[''] after:block after:w-12 after:h-[2px] after:bg-red-500 after:absolute after:left-1/2 after:bottom-0 after:transform after:-translate-x-1/2">
              Awards and Recognitions
            </h2>

        </div>
        <div className='grid grid-cols-2 items-center'>
          <div >
            <p className='text-black'>
              <li> Forbes 30 Under 30 for 2024 </li>
              <li> Multiple grants and accolades from industry leaders and foundations.</li>
            </p>   
          </div>
        
          <div className='relative flex  justify-center items-end h-28'>
            <img 
              src={achievement2} 
              alt="award"
              className='absolute top-0 h-10 w-[30%] mb-6 object-fill' 
            />
            <img 
              src={achievement1} 
              alt="award"
              className='absolute left-[25%] bottom-0 h-10 w-[30%] object-fill' 
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Awards
