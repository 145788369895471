import React from "react";
import { smart, grandeursmart, intelkard } from '../../assets/index';
import { Link } from "react-router-dom";

export default function OurSubsidiary() {
    return (
        <div>
            <h1>Our Subsidiary</h1>
            <div className="custom-scrollbar flex items-center justify-around mx-[10%] mt-4 smd:px-[5%] smd:py-[5%] lg:px-20 lg:py-10 bg-gray-200 smd:overflow-x-scroll smd:gap-10 rounded-lg">
                <Link to="/smart" >
                    <img src={smart} alt="smart" className="w-32 smd:w-32 2xl:w-64" />
                </Link>
                <Link to="https://grandeursmart.com/" >
                    <img src={grandeursmart} alt="grandeursmart" className="w-32 2xl:w-64" />
                </Link>
                <Link to="https://www.intelkard.com/">
                <img src={intelkard} alt="intelkard" className="w-32 2xl:w-64" />
                </Link>
            </div>
        </div>
    );
}
