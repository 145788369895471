import React from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { introBg } from "../assets";
import { iotUseCasesData } from "../data/data3";
import { Helmet } from 'react-helmet-async';
import OtherServices from "../components/OtherServices";



export default function IotUseCases(){

    return (
        <div className="bg-slate-100" >
            <Helmet>
                <title>Mainlogix IoT Use Cases | Innovative IoT Solutions</title>
                <meta name="description" content="Discover diverse IoT use cases from Mainlogix, showcasing how our IoT solutions drive efficiency, innovation, and automation across various industries. Explore practical applications in smart homes, industrial automation, healthcare, and agriculture, illustrating how our IoT technology transforms operations, enhances connectivity, and delivers actionable insights for smarter decision-making and improved quality of life." />
            </Helmet>

            <Header />

            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white">
                        IoT Use Cases
                    </h2>
                    <p className="text-center text-white mt-4">
                        Real-world applications of IoT technology, showcasing 
                        how connected devices are transforming industries, enhancing 
                        efficiency, and driving innovation across different sectors. 
                    </p>
                </div>
            </div>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 smd:my-5 my-20 mx-[5%]">
                {iotUseCasesData.slice(0, 6).map((iotUseCase, index) => (
                    <div key={index} className="rounded-lg 2xl:rounded-2xl overflow-hidden shadow-lg flex flex-col pb-12 h-[21rem] 2xl:h-[35rem]">
                        <img
                            src={iotUseCase.img}
                            alt="Real Time Operations"
                            className="w-full h-52 2xl:h-96 object-cover"
                        />
                        <div className="flex-1 flex flex-col justify-between gap-1 py-4 px-1">
                            <h3 className="text-xl text-center font-semibold m-0 leading-tight">{iotUseCase.heading}</h3>
                            <p className="text-black text-center mt-2 leading-normal">{iotUseCase.content}</p>
                        </div>
                    </div>
                ))}
            </div>

            <OtherServices />

            <Footers />
        </div>
    )
}


