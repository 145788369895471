// import React from 'react';
// import { servicesData } from '../../data/data1';
// import ServiceCard from './ServiceCard';
// import { useLocation } from 'react-router-dom';

// function ServicesPage() {

//   const location = useLocation();

//   return (
//     <div className="max-w-screen-xl mx-auto py-5 px-4">
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//         {servicesData.map(service => (
//           <ServiceCard 
//             key={service.id}
//             title={service.title}
//             des={service.des}
//             link={service.link}
//             image={service.image}
//           />
//         ))}
//       </div>
     
//     </div>
//   );
// }

// export default ServicesPage;


import React from 'react';
import { useLocation } from 'react-router-dom';
import { servicesData } from '../../data/data1';
import ServiceCard from './ServiceCard';

function ServicesPage() {
  const location = useLocation();
  
  // Access the clicked card's id from the route state
  const clickedCardId = location.state?.clickedCardId;

  // Filter out the clicked card
  const remainingServices = servicesData.filter(service => service.id !== clickedCardId);

  return (
    <div className="max-w-screen-xl mx-auto py-5 px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {remainingServices.slice(0, 6).map(service => (
          <ServiceCard 
            key={service.id}
            id={service.id}
            title={service.title}
            des={service.des}
            link={service.link}
            image={service.image}
          />
        ))}
      </div>
    </div>
  );
}

export default ServicesPage;
