import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminDashboard.css'; // For styling
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('contacts');
  const [contacts, setContacts] = useState([]);
  const [newsletters, setNewsletters] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch contacts and newsletters data from backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const contactsRes = await axios.get('https://144.126.154.36:3100/contact');
        const newslettersRes = await axios.get('https://144.126.154.36:3100/newsletter');
        setContacts(contactsRes.data);
        setNewsletters(newslettersRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Simple loading state
  }

  return (
    <div className="admin-dashboard-container">
      <h2 className="text-2xl font-bold text-center mb-6">Admin Dashboard</h2>
        <div className="tabs flex justify-center space-x-4 mb-6">
            <button
            className={`tab-button ${activeTab === 'contacts' ? 'active' : ''}`}
            onClick={() => setActiveTab('contacts')}
            >
            Contacts
            </button>
            <button
            className={`tab-button ${activeTab === 'newsletter' ? 'active' : ''}`}
            onClick={() => setActiveTab('newsletter')}
            >
            Newsletter
            </button>
            <button
            className="tab-button logout"
            onClick={() => {
                sessionStorage.removeItem('isAdmin');
                navigate('/admin/signin');
            }}
            >
            Logout
            </button>
        </div>

      <hr />

      <div className="content">
        {activeTab === 'contacts' && (
          <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Contact Submissions</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {contacts.length > 0 ? (
                contacts.map(contact => (
                  <div key={contact.id} className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold">Contact #{contact.id}</h2>
                    <p className="text-gray-600">First Name: {contact.firstName}</p>
                    <p className="text-gray-600">Last Name: {contact.lastName}</p>
                    <p className="text-gray-600">company: {contact.company}</p>
                    <p className="text-gray-600">Phone: {contact.phone}</p>
                    <p className="text-gray-600">Business Email: {contact.businessEmail}</p>
                    <p className="text-gray-600">Message: {contact.message}</p>
                    <p className="text-gray-600">Submitted At: {new Date(contact.submittedAt).toLocaleString()}</p>
                    <p className="text-gray-600">TimeZone: {contact.timeZone}</p>
                  </div>
                ))
              ) : (
                <p>No contacts available.</p>
              )}
            </div>
          </div>
        )}

        {activeTab === 'newsletter' && (
          <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Newsletter Subscriptions</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {newsletters.length > 0 ? (
                newsletters.map(newsletter => (
                  <div key={newsletter.id} className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold">Subscriber #{newsletter.id}</h2>
                    <p className="text-gray-600">Email: {newsletter.email}</p>
                    <p className="text-gray-600">Subscribed At: {new Date(newsletter.subscribedAt).toLocaleString()}</p>
                  </div>
                ))
              ) : (
                <p>No newsletter subscribers available.</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
