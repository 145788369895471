import React from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { introBg } from "../assets";
import { expertiseData } from "../data/data3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from 'react-helmet-async';



export default function Expertise(){

    return (
        <div className="bg-white" >

            <Helmet>
                <title>Mainlogix Industries Expertise | Tailored Solutions for Diverse Sectors</title>
                <meta name="description" content="Discover Mainlogix's extensive expertise across various industries, including healthcare, manufacturing, agriculture, and smart homes. Our specialized solutions and innovative technologies are designed to address the unique challenges and requirements of each sector, driving efficiency, enhancing performance, and enabling transformative results. Learn how our industry-specific insights and tailored approaches can help your business thrive in a rapidly evolving landscape." />
            </Helmet>


            <Header />

            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white">
                    Industries Expertise
                    </h2>
                    <p className="text-center text-white mt-4">
                    Explore Our Deep Expertise Across Industries, 
                    Delivering Tailored IoT and Automation Solutions 
                    that Drive Innovation and Transform Operations.
                    </p>
                </div>
            </div>


                <div className="py-10 smd:px-3 px-40 relative flex flex-col justify-center">
                    <h2 className="title-text font-poppins font-semibold text-center text-[40px] text-black">
                        In Which Expertise We Will Help You?
                    </h2>
                    <p className=" text-center px-[5%] mt-4">
                        Discover the Specialized Expertise We 
                        Bring to Your Industry, Helping You Overcome 
                        Challenges, Optimize Performance, and Innovate 
                        with Confidence.
                    </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-[5%] mb-10">
                    {expertiseData.slice(0, 9).map((expert, index) => (
                    <div key={index} className="rounded-lg overflow-hidden shadow-lg flex flex-col h-full">
                        
                        <div className="flex flex-col justify-between flex-1 p-4">
                            <FontAwesomeIcon icon={expert.icon} color="white" className="w-4  p-2 my-2 bg-red-700 rounded-full" />
                            <div>
                                <h3 className="text-xl 2xl:text-2xl font-semibold mb-2">{expert.heading}</h3>
                                <p className="text-black mb-4">{expert.content}</p>
                            </div>
                            
                        </div>
                    </div>
                    ))}
                </div>
            

            <Footers />
        </div>
    )
}


