import { faArrowRight, faGlobe, faCompass } from '@fortawesome/free-solid-svg-icons';
import * as pic from '../assets/index';


export const embeddedLinuxDevData = [
    {
        icon: faGlobe,
        heading: "Custom Device Drivers",
        content: "Developing and integrating custom device drivers to ensure seamless communication between hardware and software components, enhancing overall system performance.",
    },
    {
        icon: faGlobe,
        heading: "Real-Time Performance Optimization",
        content: "Optimizing systems for real-time performance to ensure that critical tasks are executed within stringent time constraints, crucial for applications requiring immediate response.",
    },
    {
        icon: faGlobe,
        heading: "Secure Boot",
        content: "Implementing secure boot processes to protect the system from unauthorized access and ensure that only trusted software is executed, enhancing security.",
    },
    {
        icon: faGlobe,
        heading: "Kernel Augmentation",
        content: "Augmenting the Linux kernel to add new features or improve existing ones, ensuring that your system meets the specific requirements of your application.",
    },
    
]



export const productsData = [
    {
        icon: faGlobe,
        heading: "Tracking Devices",
        content: "Our cutting-edge tracking devices provide real-time location monitoring and security for your valuables, vehicles, and bicycles.",
    },
    {
        icon: faGlobe,
        heading: "Smart Lock for Homes and Private Lock Rooms/Boxes",
        content: "Upgrade the security of your home and private areas with our smart locks, crafted to meet modern security demands.",
    },
    {
        icon: faGlobe,
        heading: "Smart Irrigation Control for Grass",
        content: "Enhance water efficiency and maintain a vibrant lawn with our smart irrigation control system.",
    },
    {
        icon: faGlobe,
        heading: "Elderly and Mental Health Kit",
        content: "Promote the health and well-being of elderly family members with our all-inclusive health monitoring kit.",
    },
    {
        icon: faGlobe,
        heading: "Item Counting System",
        content: "Streamline inventory management and boost operational efficiency with our advanced item counting system.",
    },
    {
        icon: faGlobe,
        heading: "Truck Tracking",
        content: "Optimize fleet management and ensure vehicle security with our comprehensive truck tracking solution.",
    },
    {
        icon: faGlobe,
        heading: "Embedded Board with Microcontroller for Different Uses like Gateway",
        content: "Our flexible embedded boards with microcontrollers are perfect for a range of applications, including gateways, industrial automation, and IoT solutions.",
    },
    {
        icon: faGlobe,
        heading: "AIOT (Artificial Intelligence of Things)",
        content: "Combine AI with IoT to develop intelligent, interconnected systems for a variety of applications.",
    },
    {
        icon: faGlobe,
        heading: "Anti-RFID Wallets and Cases",
        content: "Safeguard your personal information and protect against electronic theft with our Anti-RFID wallets and cases.",
    },
    {
        icon: faGlobe,
        heading: "AI Camera with Intelligent Features",
        content: "Enhance your surveillance and monitoring capabilities with our AI cameras, equipped with smart features for superior security and analysis.",
    },
    {
        icon: faGlobe,
        heading: "NFC Solutions for Various Use Cases",
        content: "Utilize NFC technology with our versatile solutions, ideal for applications like Google reviews, social media tags, and more.",
    },
    
]


export const nfcServicesData = [
    {
        img: `${pic.nfcReviews}`,
        heading: "Google Reviews Made Easy",
        content: "Simplify the process of collecting customer feedback with our NFC-enabled Google Review Tags. Customers can simply tap their smartphone on an NFC tag at your business premises to instantly access your Google Review page, leaving feedback with ease. This effortless interaction boosts your online reputation and customer satisfaction.",
    },
    {
        img: `${pic.nfcSocialMedia}`,
        heading: "Social Media Following Tag",
        content: "Increase your social media presence with NFC-enabled follow tags. These tags can be placed in your store, at events, or even on promotional material, allowing users to quickly follow your social media profiles with a single tap. This improves your brand's visibility and engagement without the need for manual searching.",
    },
    {
        img: `${pic.nfcBusinessCard}`,
        heading: "Smart Business Card",
        content: "Upgrade your networking with our NFC-powered Smart Business Cards. Instead of handing out traditional paper cards, simply tap your NFC card on someone’s phone, instantly sharing your contact details, LinkedIn profile, or even a portfolio. It’s an eco-friendly, high-tech way to make a lasting impression.",
    },
    {
        img: `${pic.nfcAccess}`,
        heading: "Access Control & Security",
        content: "Streamline access control to offices, buildings, or restricted areas with our NFC-based solutions. Users can unlock doors with a tap of their phone or NFC-enabled access card. This secure and convenient system eliminates the need for traditional keys or physical contact, enhancing both security and hygiene.",
    },
    {
        img: `${pic.nfcAttendence}`,
        heading: "Event Ticketing & Attendance Tracking",
        content: "Manage event ticketing and attendance effortlessly with our NFC-enabled tags. Whether for conferences, concerts, or corporate events, attendees can tap their NFC tickets for seamless entry. Organizers can track attendance in real-time and gather valuable event data.",
    },
    {
        img: `${pic.nfcProduct}`,
        heading: "Contactless Payments",
        content: "With our NFC-enabled payment systems, businesses can offer a fast and secure way for customers to make payments. NFC payments reduce checkout times and provide a contactless, modern transaction experience that today’s customers expect.",
    },
    {
        img: `${pic.consultService7}`,
        heading: "Inventory Management",
        content: "NFC technology can be used to streamline inventory management. Each product can be tagged with an NFC chip that stores relevant data, which can be scanned and updated in real time, reducing manual entry errors and improving stock accuracy.",
    },
    {
        img: `${pic.consultService3}`,
        heading: "Product Authentication",
        content: "Use NFC tags to combat counterfeit products. Customers can verify the authenticity of high-end goods like electronics, luxury items, and pharmaceuticals with a simple tap of their phone, giving them peace of mind and trust in your brand.",
    },
]

export const aiotServicesData = [
    {
        img: `${pic.consultService1}`,
        heading: "Smart Homes",
        content: "Imagine a home that knows your routine, adjusts the lights, temperature, and even locks the doors—all on its own. Our AIoT solutions turn ordinary homes into smart, adaptive spaces that work around your lifestyle.",
    },
    {
        img: `${pic.consultService2}`,
        heading: "Healthcare Revolution",
        content: "AIoT is changing healthcare. From smart devices that track your health in real-time to remote monitoring systems that alert doctors instantly, it’s about better care, faster decisions, and healthier lives.",
    },
    {
        img: `${pic.consultService3}`,
        heading: "Smarter Industries",
        content: "In factories, AIoT keeps everything running smoothly. Machines that predict when they’ll need maintenance, supply chains that optimize themselves—AIoT takes efficiency to a whole new level in the industrial world.",
    },
    {
        img: `${pic.consultService4}`,
        heading: "Farming with Intelligence",
        content: "With AIoT, agriculture becomes smarter. Our systems help farmers monitor crops, predict weather changes, and automate tasks, leading to better yields with less effort.",
    },
    {
        img: `${pic.consultService5}`,
        heading: "Building Smart Cities",
        content: "AIoT is the backbone of tomorrow’s cities. From managing traffic and energy to improving public safety, our technology helps create cities that are more efficient, sustainable, and responsive to the needs of their residents.",
    },
    
]

export const petIotData = [
    {
        title: "Where is My Pet?",
        des: "Track your pet’s location in real time, whether they’re indoors or outdoors.",
        link: "/iot-managed-services",
        image: `${pic.pet3}`,
    },
    {
        title: "Health Monitoring & Insights",
        des: "Non-invasive sensors provide real-time health data, helping you monitor your pet’s well-being through secure cloud storage.",
        link: "/iot-managed-services",
        image: `${pic.pet8}`,
    },
    {
        title: "Predictive Care",
        des: "Our algorithms predict potential health issues, enabling early intervention and reducing the need for invasive treatments.",
        link: "/iot-managed-services",
        image: `${pic.pet4}`,
    },
    {
        title: "Anti-Barking System",
        des: "Manage excessive barking with non-harmful, intelligent solutions for a quieter environment.",
        link: "/iot-managed-services",
        image: `${pic.pet2}`,
    },
]


export const grandeurSmartData = [
    {
        title: "Where is My Pet?",
        des: "Track your pet’s location in real time, whether they’re indoors or outdoors.",
        link: "/iot-managed-services",
        image: `${pic.pet3}`,
    },
    {
        title: "Health Monitoring & Insights",
        des: "Non-invasive sensors provide real-time health data, helping you monitor your pet’s well-being through secure cloud storage.",
        link: "/iot-managed-services",
        image: `${pic.pet8}`,
    },
    {
        title: "Predictive Care",
        des: "Our algorithms predict potential health issues, enabling early intervention and reducing the need for invasive treatments.",
        link: "/iot-managed-services",
        image: `${pic.pet4}`,
    },
    {
        title: "Anti-Barking System",
        des: "Manage excessive barking with non-harmful, intelligent solutions for a quieter environment.",
        link: "/iot-managed-services",
        image: `${pic.pet2}`,
    },
]



export const grandeurSmartFeaturesData = [
    {
        title: "High-Performance Edge Computing",
        des: "Powered by the RK3328 Quad-core Cortex-A53 CPU, the gateway delivers reliable edge computing, reducing latency and enabling real-time decision-making for mission-critical applications.",
        link: "/iot-managed-services",
        image: `${pic.pet3}`,
    },
    {
        title: "Modular Connectivity Options",
        des: "The gateway supports a wide array of wireless and wired protocols to ensure flexible communication with IoT devices:",
        des1: "Wireless Protocols:",
        list1: [
            ['Zigbee:', 'Low-power, reliable protocol for smart home automation and lighting.'],
            ['Z-Wave:', 'Long-range, energy-efficient communication for smart devices.'],
            ['Matter:', 'Ensures interoperability across brands for smart home connectivity.'],
            ['BLE (Bluetooth Low Energy):', 'Energy-efficient, short-range communication for device interactions.'],
            ['LoRaWAN:', 'Long-range, low-power communication for industrial applications.'],
            ['GPS:', 'Accurate location tracking and geofencing for mobile devices.'],
            ['Wi-Fi:', 'High-speed wireless communication for real-time control and monitoring.'],
            ['LTE (Cat 4 and M1):', ' Cellular connectivity for remote monitoring, ensuring continuous device operation even without traditional internet.'],
            ['MQTT:', 'Lightweight protocol for machine-to-machine communication, perfect for IoT networks with minimal data requirements.'],
          ],
        des2: "Wired Protocols",
        list2: [
            ['Ethernet:', 'Fast, secure wired communication for mission-critical operations, ensuring uninterrupted performance in industrial or commercial environments.'], 
          ],
        link: "/iot-managed-services",
        image: `${pic.pet8}`,
    },
    {
        title: "Backup Power",
        des: "Equipped with a 5000mAh Li-ion battery, the gateway provides backup power during outages, making it reliable for critical applications like healthcare or industrial monitoring.",
        link: "/iot-managed-services",
        image: `${pic.pet4}`,
    },
    {
        title: "Customizable for Specific Use Cases",
        des: "The modular architecture allows the gateway to be customized for various applications, including smart cities, healthcare, agriculture, and industrial IoT.",
        link: "/iot-managed-services",
        image: `${pic.pet2}`,
    },
]


export const grandeurSmartUseCasesData = [
    {
        title: "Seamless Connectivity Across Protocols",
        des: "The support for multiple wireless and wired protocols ensures the gateway can integrate with a wide variety of IoT ecosystems, making it versatile for industries such as healthcare, transportation, and energy management.",
        link: "/iot-managed-services",
        image: `${pic.pet3}`,
    },
    
    {
        title: "Real-Time Edge Computing",
        des: "The high-performance CPU enables fast data processing, allowing for real-time decision-making in industrial automation, remote monitoring, and smart city infrastructure.",
        link: "/iot-managed-services",
        image: `${pic.pet4}`,
    },
    {
        title: "Flexibility and Scalability",
        des: "With its modular design and support for multiple protocols, the gateway can be scaled to meet the demands of growing IoT deployments, from managing small smart home setups to large industrial networks.",
        link: "/iot-managed-services",
        image: `${pic.pet2}`,
    },
    {
        title: "Reliable Operation with Backup Power",
        des: "The built-in Li-ion battery ensures the gateway remains operational during power outages, providing reliability for critical applications like emergency services or energy grid management.",
        link: "/iot-managed-services",
        image: `${pic.pet2}`,
    },
    {
        title: " Data Privacy and Security",
        des: "Featuring hardware encryption and secure OS support, the gateway protects sensitive data, whether used in healthcare devices, industrial sensors, or government facilities.",
        link: "/iot-managed-services",
        image: `${pic.pet2}`,
    },
    {
        title: "Remote Access and Monitoring",
        des: "Monitor and control IoT systems from anywhere in the world. The gateway’s remote access capabilities make it ideal for managing smart cities, remote agricultural setups, or industrial operations.",
        link: "/iot-managed-services",
        image: `${pic.pet2}`,
    },
]






