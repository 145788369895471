import React from 'react'
import Hero from '../components/About/Hero'
import Journey from '../components/About/Journey'
import Mission from '../components/About/Mission'
import Awards from '../components/About/Awards'
import Header from '../components/home/Header'
import Footers from '../components/home/Footers'
import { Helmet } from 'react-helmet-async';

const About = () => {
  return (
    <div >
      <Helmet>
          <title>About Mainlogix</title>
          <meta name="description" content="Mainlogix offers cutting-edge IoT solutions and automation technologies, including smart sensors, locks, and gateways. Discover advanced, reliable, and seamless integration solutions designed to enhance efficiency, security, and innovation for your business. Contact us for expert consultation and transformative technology solutions." />
      </Helmet>
      <Header />
      <Hero />
      <div className='mx-[5%]'>
        {/* <Journey /> */}
        <Mission />
        <Awards />
      </div>
      <Footers />
    </div>
  )
}

export default About
