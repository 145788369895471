import React from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { introBg, iotAppDevelopment,} from "../assets";
import { consultingServicesData, } from "../data/data3";
import { iotServicesData } from "../data/data1";
import { Helmet } from 'react-helmet-async';
import ServicesPage from "../components/home/ServicesPage";
import { Link } from "react-router-dom";


export default function ConsultingServices(){

    return (
        <div className="bg-slate-100" >

            <Helmet>
                <title>Mainlogix Consulting Services | Advanced IoT Solutions for Home and Business</title>
                <meta name="description" content="Discover Mainlogix's extensive IoT services tailored for both residential and commercial needs. Our offerings include IoT consulting, custom solutions, smart home automation, device integration, and data analytics. We specialize in enhancing home convenience, optimizing business operations, and providing end-to-end support to elevate your IoT infrastructure. Experience seamless connectivity and smart automation with our innovative IoT solutions." />
            </Helmet>


            <Header />

            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white">
                        IOT Services
                    </h2>
                    <p className="text-center text-white mt-4">
                        Explore our comprehensive IoT services designed to drive innovation and efficiency across various industries. From consultation to deployment, we provide end-to-end solutions that transform your ideas into connected realities, optimizing performance and enhancing user experiences.
                    </p>
                </div>
            </div>

                

            <div className="page-title pt-10 px-40 relative flex flex-col justify-center">
                <h2 className="title-text font-poppins font-semibold text-center text-[40px] text-black">
                    Consulting Services
                </h2>
                <p className=" text-center px-[5%] mt-4">
                    At Mainlogix, our IoT consulting approach is tailored to guide businesses through the complexities of the IoT landscape. We start by analyzing your specific business needs and identifying the IoT framework that aligns with your goals. From there, we rapidly develop and implement customized IoT solutions that meet your objectives. Our end-to-end consulting services include:
                </p>
            </div>

            <div className="mx-[5%]">
                <ServicesPage />
            </div>

            <div className="flex justify-center mb-5">
                <Link to="/contact" >
                    <button className='button-m text-center'>Contact Us</button>
                </Link>
            </div>

            <Footers />
        </div>
    )
}


