import React from "react";
import { Helmet } from "react-helmet-async";
import Header from "../components/home/Header";
import { introBg, iotAppDevelopment, rto1 } from "../assets";
import Footers from "../components/home/Footers";
import { aiotServicesData, } from "../data/data4";
import OtherServices from "../components/OtherServices";
import { IotDataAnalyticsServices, iotServicesData } from "../data/data1";

const IotDataAnalytics = () => {
    return (
        <div>
            <Helmet>
                <title>IOT Data and Analytics Service | Mainlogix</title>
                <meta name="AIOT" content="Transform IoT data into actionable insights. Our expert data science team delivers predictive analytics and optimization solutions for your business." />
            </Helmet>
            <Header />
            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[20%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white ">
                        IoT Data and Analytics
                    </h2>
                    <p className="text-center text-white mt-4">
                        Transforming raw IoT data into actionable insights, driving informed decision-making and optimizing performance through advanced analytics and machine learning.
                    </p>
                </div>
            </div>

                <div className="bg-white py-10">
                    <div>
                        <h1 >Unlocking the Power of Your Data</h1>
                        <p className="text-center smd:px-3 px-[15%] pb-0">
                            At Mainlogix Technology, we help you turn IoT data into smart decisions. Every connected device generates valuable information—our job is to make sure that data works for you. From real-time monitoring to advanced analytics, we provide insights that drive efficiency and optimize operations.
                        </p>
                              
                    </div>
                </div>

                <div className="smd:mx-3 mx-12 mt-5 rounded-xl 2xl:rounded-3xl overflow-hidden border-b-neutral-600 border-[1px] mb-10">

                    <div className="grid smd:grid-cols-1 grid-cols-2">
                        <div className="flex justify-center overflow-hidden bg-white">
                            <img 
                                src={iotAppDevelopment}
                                className="object-cover" 
                                alt="Our solutions" 
                            />
                        </div>
                        <div>
                            <div>   
                                <div className="px-5"  >
                                    <h3>Our Services</h3>
                                    
                                    <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
                                        <ul className="list-disc smd:px-3 px-6 2xl:text-2xl">
                                            {IotDataAnalyticsServices.map((item, index) => (
                                                item.ourServices.map((expertise, subIndex) => (
                                                    <li key={`${index}-${subIndex}`}>
                                                        <strong>{expertise[0]}</strong> {expertise[1]}
                                                    </li>
                                                ))
                                            ))}
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid smd:grid-cols-1 grid-cols-2 items-center ">   
                        <div>
                            <div>   
                                <div className="px-5">
                                    <h3>Industries We Serve</h3>
                                    
                                    <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
                                        <ul className="list-disc smd:px-3 px-6 2xl:text-2xl">
                                            {IotDataAnalyticsServices.map((item, index) => (
                                                item.industries.map((expertise, subIndex) => (
                                                    <li key={`${index}-${subIndex}`}>
                                                        <strong>{expertise[0]}</strong> {expertise[1]}
                                                    </li>
                                                ))
                                            ))}
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center overflow-hidden bg-white">
                            <img 
                                src={rto1}
                                className="object-cover" 
                                alt="Our solutions" 
                            />
                        </div>
                    </div>
                </div>

                <OtherServices />


                <Footers />
            
        </div>
    )
};

export default IotDataAnalytics;