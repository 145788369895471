import { faArrowRight, faGlobe, faCompass } from '@fortawesome/free-solid-svg-icons';
import * as pic from '../assets/index';

export const footerData = [
    {
        heading: "SMART HOME",
        content: ['Smart Heating',
            'Smart Gardening',
            'Video Doorbells',
            'Personal Assistants',
        ]
    },
    {
        heading: "SMART HEALTHCARE",
        content: ['Remote Patient Monitoring',
            'Health & Safety Solutions',
            'Modernize Patient Assistance',
        ]
    },
    {
        heading: "AUTOMATIVE",
        content: ['Monitoring',
            'Connected Vehicles',
            'Remote Vehicle Diagnostics',
            'Vehicle Tracking',
        ]
    },
    {
        heading: "RETAIL",
        content: ['Retail Supply Chain',
            'Smart Shelves',
            'Delivery Operations',
            'Warehouse Automation',
        ]
    },
    {
        heading: "SMART MANUFACTURING",
        content: ['Product Asset Management',
            'Industry 4.0',
            'Performance Optimization',
            'Safety/Security',
        ]
    }
]

export const expertiseData = [
    {
        icon: faGlobe,
        heading: "Smart Agriculture",
        content: "We empower farmers with IoT-driven solutions that optimize crop management, monitor soil conditions, and automate irrigation. Our smart agriculture expertise helps you increase yield, reduce waste, and make data-driven decisions, ensuring sustainable and efficient farming operations.",
    },
    {
        icon: faGlobe,
        heading: "Smart City",
        content: "Our smart city solutions integrate IoT to enhance urban living. From intelligent traffic management to smart lighting and waste management, we help cities become more efficient, sustainable, and livable, improving the quality of life for residents.",
    },
    {
        icon: faGlobe,
        heading: "Industrial IoT",
        content: "We specialize in connecting industrial systems to IoT networks, enabling real-time monitoring, predictive maintenance, and automation. Our industrial IoT solutions drive efficiency, reduce downtime, and enhance safety across manufacturing and production environments.",
    },
    {
        icon: faGlobe,
        heading: "Smart Factory",
        content: "Transform your manufacturing operations with our smart factory solutions. We leverage IoT to connect machines, optimize production lines, and automate processes, enabling you to achieve higher efficiency, flexibility, and quality in your factory operations.",
    },
    {
        icon: faGlobe,
        heading: "Smart Building",
        content: "We make buildings smarter with IoT solutions that enhance energy efficiency, security, and comfort. Our expertise in smart buildings allows for automated control of lighting, HVAC, and security systems, creating a more sustainable and responsive environment.",
    },
    {
        icon: faGlobe,
        heading: "Smart Energy & Utility",
        content: "Our smart energy solutions integrate IoT to optimize energy usage, manage grids, and support renewable sources. We help utilities and energy providers increase efficiency, reduce costs, and improve service reliability through advanced monitoring and automation.",
    },
    {
        icon: faGlobe,
        heading: "Smart Transportation",
        content: "We revolutionize transportation systems with IoT-enabled solutions that improve safety, efficiency, and convenience. From connected vehicles to smart infrastructure, our expertise in smart transportation ensures smoother, more reliable, and eco-friendly journeys.",
    },
    {
        icon: faGlobe,
        heading: "Healthcare",
        content: "Our IoT solutions in healthcare enhance patient care through remote monitoring, data analytics, and connected devices. We help healthcare providers improve outcomes, streamline operations, and offer personalized care, all while ensuring data security and compliance.",
    },
    {
        icon: faGlobe,
        heading: "Retail",
        content: "Elevate your retail experience with IoT solutions that enhance customer engagement, optimize inventory management, and streamline operations. Our expertise in smart retail helps you create personalized shopping experiences, improve supply chain efficiency, and drive growth.",
    },
    
]


export const generalFeaturesData = [
    {
        icon: faGlobe,
        heading: "Vehicles",
        content: "Seamlessly integrate vehicles into your IoT ecosystem, enhancing fleet management, optimizing routes, and ensuring real-time monitoring for improved operational efficiency.",
    },
    {
        icon: faGlobe,
        heading: "Sensors",
        content: "Deploy a network of sensors to capture crucial data, enabling real-time decision-making and proactive management across various applications and environments.",
    },
    {
        icon: faGlobe,
        heading: "Probes",
        content: "Leverage probes for precise data collection in challenging environments, ensuring accurate monitoring and control over critical parameters.",
    },
    {
        icon: faGlobe,
        heading: "Spreader Controllers",
        content: "Enhance the efficiency of agricultural and industrial processes with spreader controllers, providing precise control over the distribution of materials.",
    },
    {
        icon: faGlobe,
        heading: "Smart Building",
        content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation.",
    },
] 

export const realTimeOPerationGFData = [
    {
        img: `${pic.rto1}`,
        heading: "Smart Building",
        content: "Transform buildings into smart environments with real-time monitoring and control, enhancing energy efficiency, security, and comfort.",
    },
    {
        img: `${pic.rto2}`,
        heading: "Live Map",
        content: "Visualize and track assets in real-time with a live map, enabling immediate insights and actions for improved operational efficiency.",
    },
    {
        img: `${pic.rto3}`,
        heading: "Vehicle History",
        content: "Access comprehensive vehicle history data to analyze performance, maintenance needs, and operational trends for informed decision-making.",
    },
]



export const geographicLayersData = [
    {
        id: 1,
        title: "Roads",
        des: "Integrate detailed road data into your IoT solutions, optimizing route planning, traffic management, and infrastructure maintenance.",
        link: "/",
        icon: faGlobe
    },
    {
        id: 2,
        title: "Infrastructure Network",
        des: "Monitor and manage your infrastructure network with detailed geographic data, ensuring timely maintenance and efficient operation.",
        link: "/",
        icon: faCompass
    },
    {
        id: 3,
        title: "Drone Images",
        des: "Utilize drone imagery for detailed aerial views, enhancing monitoring, inspection, and analysis of vast or hard-to-reach areas.",
        link: "/",
        icon: faArrowRight
    },
    {
        id: 4,
        title: "Custom Layers",
        des: "Create custom geographic layers tailored to your specific needs, providing a unique perspective and deeper insights into your operations.",
        link: "/",
        icon: faArrowRight
    }
]


export const reportingFeaturesData = [
    {
        id: 1,
        title: "Alerts",
        des: "Receive instant alerts on critical events, enabling swift action and minimizing potential disruptions in your operations.",
        link: "/",
        icon: faGlobe
    },
    {
        id: 2,
        title: "Notifications",
        des: "Stay updated with timely notifications, ensuring you are always aware of important developments and can respond effectively.",
        link: "/",
        icon: faCompass
    },
    {
        id: 3,
        title: "Reports",
        des: "Generate detailed reports to analyze performance, trends, and outcomes, supporting informed decision-making and strategic planning.",
        link: "/",
        icon: faArrowRight
    },
    {
        id: 4,
        title: "Dashboard",
        des: "Utilize intuitive dashboards to visualize key metrics and insights, providing a clear overview of your operations and enabling real-time monitoring.",
        link: "/",
        icon: faArrowRight
    }
]


export const iotUseCasesData = [
    {
        img: `${pic.iotCase1}`,
        heading: "Smart Home",
        content: "Smart Heating, Smart Gardening, Video Doorbells, Personal Assistants.",
    },
    {
        img: `${pic.iotCase2}`,
        heading: "Smart Healthcare",
        content: " Remote Patient Monitoring, Health & Safety Solutions, Modernize Patient Assistance.",
    },
    {
        img: `${pic.iotCase3}`,
        heading: "Automotive",
        content: "Fleet Management & Monitoring, Connected Vehicles, Remote Vehicle Diagnostics, Vehicle Tracking.",
    },
    {
        img: `${pic.iotCase4}`,
        heading: "Retail",
        content: "Retail Supply Chain, Smart Shelves, Delivery Operations, Warehouse Automation.",
    },
    {
        img: `${pic.iotCase5}`,
        heading: "Smart Manufacturing",
        content: "Product Asset Management, Industry 4.0, Performance Optimization, Safety/Security.",
    },
]



export const existingSolutionsData = [
    {
        id: 1,
        title: "IoT & Vehicle Tracking Web Platform",
        des: "Our IoT & Vehicle Tracking Web Platform offers comprehensive real-time monitoring and management of vehicle fleets. The platform provides detailed insights into vehicle locations, driving patterns, and maintenance needs, all accessible through an intuitive web interface. With customizable alerts and reporting, businesses can optimize fleet operations, enhance security, and reduce operational costs.",
        link: "/",
        icon: faGlobe
    },
    {
        id: 2,
        title: "Predefined Routes",
        des: "Streamline your logistics with our Predefined Routes feature, which allows you to set and manage specific routes for your fleet. This feature ensures that vehicles follow the most efficient paths, reducing fuel consumption and improving delivery times. With the ability to monitor adherence to predefined routes in real-time, you can enhance route planning, minimize detours, and ensure timely deliveries.",
        link: "/",
        icon: faCompass
    },
    {
        id: 3,
        title: "Mobile Navigation with 'Turn by turn' Instructions",
        des: "Empower your drivers with our Mobile Navigation feature, offering precise 'Turn by Turn' instructions directly on their mobile devices. This feature integrates seamlessly with our tracking platform, providing real-time guidance to ensure that drivers stay on course. Whether navigating through urban areas or remote locations, the mobile navigation system enhances driving efficiency, safety, and overall productivity.",
        link: "/",
        icon: faArrowRight
    },
   
]



export const IotDevelopmentServicesData = [
    {
        icon: faGlobe,
        heading: "Web Bluetooth Apps Development",
        content: "We create Web Bluetooth apps that enable real-time connectivity and communication between your devices without complex setups. Whether for smart home devices or healthcare gadgets, our apps ensure smooth, secure, and intuitive interactions, enhancing everyday convenience.",
    },
    {
        icon: faGlobe,
        heading: "Wearable Apps Development",
        content: "Our wearable apps integrate effortlessly with IoT systems to deliver real-time tracking and data right to your wrist. From fitness trackers to health monitors, we design apps that keep users connected, informed, and ahead, all while providing a user-friendly experience.",
    },
    {
        icon: faGlobe,
        heading: "iBeacon Apps Development",
        content: "With our iBeacon app development, you can engage customers in a whole new way. We build apps that use proximity-based technology to deliver personalized, location-aware services, ideal for retail, events, and smart spaces, ensuring your audience receives the right content at the right moment.",
    },
    {
        icon: faGlobe,
        heading: "IoT and Blockchain",
        content: "We blend IoT with blockchain to ensure your data remains secure and transparent. Our solutions protect your IoT data from tampering, offering peace of mind and trust in critical areas like supply chain management and finance.",
    },
    {
        icon: faGlobe,
        heading: "IoT and Machine Learning",
        content: "Our IoT and Machine Learning solutions turn data into actionable insights. We help your devices learn and adapt, enabling predictive maintenance and smarter operations that boost efficiency and drive innovation in your business.",
    },
    {
        icon: faGlobe,
        heading: "IoT and Big Data",
        content: "We help you unlock the potential of big data within your IoT ecosystem. By transforming large volumes of data into actionable insights, our solutions empower you to make informed decisions, streamline processes, and uncover new growth opportunities.",
    },
    
]


export const IotManagedServicesData = [
    {
        icon: faGlobe,
        heading: "24/7 Human and AI Monitoring",
        content: "Our experts and AI tools keep your systems running smoothly, detecting and addressing issues in real-time.",
    },
    {
        icon: faGlobe,
        heading: "Proactive Maintenance",
        content: "We anticipate and resolve problems before they impact your operations.",
    },
    {
        icon: faGlobe,
        heading: "Comprehensive Security",
        content: "Your IoT devices are kept secure and compliant with industry standards.",
    },
    {
        icon: faGlobe,
        heading: "Tailored Solutions",
        content: "We customize our services to fit your specific needs, whether for smart homes or industrial applications.",
    },
]


export const consultingServicesData = [
    {
        img: `${pic.consultService1}`,
        heading: "IoT Managed Services",
        content: "Providing end-to-end management of your IoT infrastructure to ensure optimal performance, security, and reliability. Our managed services include monitoring, maintenance, and support to keep your IoT systems running smoothly.",
    },
    {
        img: `${pic.consultService2}`,
        heading: "IoT Applications Development",
        content: "Designing and developing custom IoT applications that integrate seamlessly with your existing systems. Our development team uses the latest technologies to create user-friendly and scalable IoT solutions.",
    },
    {
        img: `${pic.consultService3}`,
        heading: "IoT Device Management & Platform Support",
        content: "Offering robust device management and platform support services to ensure seamless integration, provisioning, and maintenance of your IoT devices.",
    },
    {
        img: `${pic.consultService4}`,
        heading: "IoT Data Science & Analytics",
        content: "Utilizing advanced data science and analytics techniques to derive actionable insights from your IoT data. We help you make informed decisions by analyzing data patterns, trends, and anomalies.",
    },
    {
        img: `${pic.consultService5}`,
        heading: "IT/OT Convergence",
        content: "Bridging the gap between Information Technology (IT) and Operational Technology (OT) to create a unified IoT ecosystem. We ensure seamless integration and interoperability between IT and OT systems to enhance efficiency and productivity.",
    },
    {
        img: `${pic.consultService6}`,
        heading: "IoT Predictive Maintenance Solution",
        content: "Implementing predictive maintenance solutions that leverage IoT data to predict equipment failures and schedule maintenance proactively.",
    },
    {
        img: `${pic.consultService7}`,
        heading: "IoT Machine Learning Services",
        content: "Applying machine learning algorithms to your IoT data to uncover hidden patterns and make intelligent predictions. Our machine learning services enhance your IoT applications with capabilities such as anomaly detection, predictive analytics, and automation.",
    },
]



export const rtosDevelopmentData = [
    {
        icon: faGlobe,
        heading: "Features of UNISON RTOS",
        content: "Utilizing UNISON RTOS, known for its lightweight, modular architecture, to provide a flexible and scalable solution tailored to the needs of IoT and wearable devices.",
    },
    {
        icon: faGlobe,
        heading: "Benefits for Wearable & IoT ",
        content: "RTOS ensures reliable and deterministic performance, making it ideal for applications where timing is critical. It supports low power consumption, making it perfect for battery-operated wearable devices.",
    },
    {
        icon: faGlobe,
        heading: "Time to Market Reduction",
        content: "By leveraging our expertise and pre-built RTOS components, we significantly reduce the development time, helping you bring your products to market faster without compromising on quality or performance.",
    },
    
    
]




