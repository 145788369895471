import React from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { introBg } from "../assets";
import { embeddedLinuxDevData } from "../data/data4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { rtosDevelopmentData } from "../data/data3";
import { Helmet } from 'react-helmet-async';


export default function OptSysDev(){

    return (
        <div className="bg-slate-100" >

        <Helmet>
            <title>Mainlogix Operating System Development | Innovative OS Solutions</title>
            <meta name="description" content="Explore Mainlogix's expertise in operating system development. We create cutting-edge, custom operating systems tailored to meet diverse needs, from embedded systems to full-featured platforms. Our development process ensures high performance, security, and scalability, leveraging the latest technologies and industry standards to deliver robust OS solutions that power advanced devices and applications." />
        </Helmet>


            <Header />

            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white">
                        Operating System Development
                    </h2>
                    <p className="text-center text-white mt-4">
                        Delve into the world of operating system development, where we create robust and scalable platforms tailored to meet the demands of modern computing. Our expertise ensures seamless integration, security, and performance across diverse environments.
                    </p>
                </div>
            </div>

            <div className="page-title py-10 px-40 relative flex flex-col justify-center">
                <h2 className="title-text font-poppins font-semibold text-center text-[40px] text-black">
                    Embedded Linux Development
                </h2>
                <p className=" text-center px-[5%] mt-4">
                    Our expertise in Embedded Linux development 
                    encompasses the creation of robust, high-performance 
                    solutions tailored to your specific needs. 
                </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 smd:py-0 py-14 smd:gap-0 gap-6 mx-[5%]">
                {embeddedLinuxDevData.slice(0, 6).map((expert, index) => (
                <div key={index} className="overflow-hidden flex flex-col h-full">
                    <div className="flex justify-center p-4 items-start">
                        <FontAwesomeIcon icon={expert.icon} color="white" className="w-4  p-2 mr-2 bg-red-700 rounded-full" />
                        <div className="flex flex-col justify-between flex-1 "> 
                            <div>
                                <h3 className="smd:text-sm text-xl 2xl:text-2xl font-semibold mb-2">{expert.heading}</h3>
                                <p className="text-black mb-4">{expert.content}</p>
                            </div>
                        </div>
                    </div>
                </div>
                ))}
            </div>

            <div className="bg-white py-10 ">
                <div className="rounded-xl p-5 shadow-xl mx-[5%] border-[1px] border-slate-200 ">
                    <h3 className="leading-normal">RTOS (Real-Time Operating System) Development</h3>
                    <p>Our RTOS development services focus on delivering high-performance, real-time solutions for wearable and IoT devices. Key features and benefits include:</p>
                    <div className=" mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {rtosDevelopmentData.slice(0, 3).map((expert, index) => (
                        <div key={index} className="rounded-lg overflow-hidden border-slate-200 border-[1px] flex flex-col h-full">
                            
                            <div className=" p-4">
                                <FontAwesomeIcon icon={expert.icon} color="white" className="w-4  p-2 my-2 bg-red-700 rounded-full" />
                                <div className="flex flex-col justify-between flex-1">
                                    <h3 className=" smd:text-sm text-xl 2xl:text-2xl font-semibold mb-2">{expert.heading}</h3>
                                    <p className="text-black mb-4">{expert.content}</p>
                                </div>
                                
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
                
            </div>

            <Footers />
        </div>
    )
}


