import React from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { productsData } from "../data/data4";
import { Helmet } from 'react-helmet-async';
import ServicesPage from "../components/home/ServicesPage";
import { introBg } from "../assets";

export default function Services() {
    const middleIndex = Math.ceil(productsData.length / 2);
    const firstColumnData = productsData.slice(0, middleIndex);
    const secondColumnData = productsData.slice(middleIndex);

    const renderColumn = (data) => (
        <div className="flex flex-col">
            {data.map((product, index) => (
                <div key={index} className="">
                    <div className="flex flex-col justify-between flex-1 py-5">
                        <div>
                            <h3 className=" text-red-600 leading-normal font-semibold">
                                {product.heading}
                            </h3>
                            <p className="text-black">{product.content}</p>
                        </div>
                    </div>
                    {index < data.length - 1 && <hr style={{ borderColor: 'black' }} />}
                </div>
            ))}
        </div>
    );

    return (
        <div className="bg-slate-100">
            <Helmet>
                <title>Mainlogix Products | Innovative IoT and Automation Solutions</title>
                <meta name="description" content="Explore Mainlogix's extensive range of innovative IoT and automation products designed to enhance your smart living experience. From advanced sensors and smart locks to cutting-edge gateways and AI-driven solutions, our products offer reliability, efficiency, and seamless integration for various applications. Discover how our state-of-the-art technology can transform your home and business with superior performance and security." />
            </Helmet>

            <Header />

            <div className="relative w-full mb-8 ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white">
                        Our Services
                    </h2>
                    <p className="text-center text-white mt-4">
                        Mainlogix Technology focuses on Innovation Through Comprehensive IoT, Automation Solutions and more.
                    </p>
                </div>
            </div>

            

            <ServicesPage />

            <div className="page-title pt-10 py-14 mx-[5%] relative flex flex-col justify-center">
                <h2 className="title-text font-poppins font-semibold text-left text-black">
                    Other Services
                </h2>
           

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-x-6 ">
                    {renderColumn(firstColumnData)}
                    {renderColumn(secondColumnData)}
                </div>
            </div>

            <Footers />
        </div>
    );
}
