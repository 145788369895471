import React from "react";
import ServicesPage from "./ServicesPage";
import { Link } from "react-router-dom";

export default function Services(){

    return (
        <div className="bg-white mt-10 smd:mx-3 mx-9">
            <div>
                <h1> Services</h1>
                <p className="text-center smd:px-3 px-[30%] pb-0">
                    Mainlogix Technology focuses on Innovation Through Comprehensive IoT, Automation Solutions and more.
                </p>
                <ServicesPage />
                <div className="flex justify-center">
                    <Link to="/services" >
                        <button className='button-m text-center '>Show more</button>
                    </Link>
                </div>



    
            </div>

        </div>
    )
}