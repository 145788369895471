import React from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { introBg, iotAppDevelopment,} from "../assets";
import { iotServicesData } from "../data/data1";
import { Helmet } from 'react-helmet-async';
import ServicesPage from "../components/home/ServicesPage";
import { IotManagedServicesData } from "../data/data3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function IotManageServices(){

    return (
        <div className="bg-slate-100" >

            <Helmet>
                <title>Mainlogix IoT Managed Services | Manage IoT Solutions for Home and Business</title>
                <meta name="description" content="Discover Mainlogix's extensive IoT services tailored for both residential and commercial needs. Our offerings include IoT consulting, custom solutions, smart home automation, device integration, and data analytics. We specialize in enhancing home convenience, optimizing business operations, and providing end-to-end support to elevate your IoT infrastructure. Experience seamless connectivity and smart automation with our innovative IoT solutions." />
            </Helmet>


            <Header />

            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
                    <h2 className="title-text font-poppins font-semibold text-[55px] text-white">
                        IOT Managed Services
                    </h2>
                    <p className="text-center text-white mt-4">
                        Delivering end-to-end management and support for your IoT infrastructure, ensuring continuous operation, security, and scalability with expert oversight.
                    </p>
                </div>
            </div>

                {/* Connected Objects */}

                <div className="bg-white">
                    <div className="py-10 smd:p-3  px-40 relative flex flex-col justify-center">
                        <h2 className="title-text font-poppins font-semibold text-center text-[40px] text-black">
                            24/7 Support with Human Expertise and AI Monitoring
                        </h2>
                        <p className=" text-center px-[5%] mt-4">
                            At Mainlogix Technology, we combine human dedication with advanced AI to manage your IoT systems around the clock. Our team of experts works alongside AI-driven tools to monitor, maintain, and optimize your IoT devices 24/7, ensuring maximum efficiency and security.
                        </p>
                    </div>


                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 py-14 gap-6 mx-[5%]">
                        {IotManagedServicesData.slice(0, 6).map((expert, index) => (
                        <div key={index} className="rounded-lg overflow-hidden shadow-lg flex flex-col h-full">
                            <div className="flex justify-center p-4 items-start">
                                <FontAwesomeIcon icon={expert.icon} color="white" className="w-4  p-2 mr-2 bg-red-700 rounded-full" />
                                <div className="flex flex-col justify-between flex-1 ">
                                    
                                    <div>
                                        <h3 className="text-xl 2xl:text-2xl font-semibold mb-2">{expert.heading}</h3>
                                        <p className="text-black mb-4">{expert.content}</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>

               

                <div className="page-title pt-10 mx-[5%] px-4 relative flex flex-col justify-center">
                    <h2 className="title-text font-poppins font-semibold text-left text-black">
                        Expore Other Services
                    </h2>
                </div>

                <div className="mx-[5%]">
                    <ServicesPage />
                </div>
            <Footers />
        </div>
    )
}


