import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { partnersData } from "../../data/data2";
import { Link } from "react-router-dom";

export default function OurPartners() {
  return (
    <div className="bg-white mt-10 smd:mx-3 mx-9">
      <div className="">
        <h1 className="relative font-bold mb-4">
          Our Partners
        </h1>

        <Link to="/" className="float-right">
            <button className='font-poppins font-semibold text-red-600 text-center text-[10px] 2xl:text-[20px]' >See More</button>
        </Link>

        <div className="flex flex-row justify-center py-6 space-x-8">
          <OwlCarousel 
            autoplay={true}   
            className="owl-theme"  
            loop={true}
            autoplayTimeout={3000}
            dots={false}
            nav={false} 
            margin={8}
            responsive={{
                0: {
                    items: 3
                },
                600: {
                    items: 3
                },
                1000: {
                    items: 5
                }
            }}
          >
          
            {partnersData.map((item, index) => (
              <div className="flex flex-col flex-1 items-center ">
                <div>
                <img 
                  key={index} 
                  src={item.image} 
                  alt={item.title} 
                  className="h-14 2xl:h-28 object-contain"
                />
                <div>
                  <p className="mt-5 flex items-center justify-center">
                    {item.title}
                  </p>
                </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
        
        
      </div>
    </div>
  );
}
