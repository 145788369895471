import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { carouselData } from '../../data/data1';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../../mainCarousel.css';
function MainCarousel() {
  return (
    <div className=' md:mx-7 lg:mx-9'>
      <div className="hidden md:block lg:block">
        <OwlCarousel 
            autoplay={true}   
            className="owl-theme"  
            loop={true}
            autoplayTimeout={6000}
            dots={true}
            nav={true} 
            margin={8}
            responsive={{
                0: {
                    items: 1
                },
                600: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }}
        >
          {carouselData.map((item, index) => (
            <div 
              key={index} 
              className="grid grid-cols-2 md:flex-row items-center justify-between md:p-10 lg:p-10 lg:gap-8 2xl:gap-12 ">
              <div className="text-left md:mr-5 ">
                <h2 className="pp-bnr mb-4">{item.title}</h2>
                <p className="mb-6">{item.des}</p>
                <a
                  href={item.link}
                  className="button-m text-left inline-flex items-center rounded-md"
                >
                  <FontAwesomeIcon icon={item.icon} className="mr-1 lg:mr-2 ml-0" />
                  {item.buttonTitle}
                  
                </a>
              </div>
              <div className="hidden w-full md:block lg:block  mt-6 md:mt-0">
                <img src={item.image} alt={item.title} className="rounded-md bg-transparent shadow-lg" />
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
      <div className=" md:hidden lg:hidden bg-[#737373]">
        
        <OwlCarousel 
            autoplay={true}   
            className="owl-theme"  
            loop={true}
            autoplayTimeout={3000}
            dots={false}
            nav={false} 
            margin={8}
            responsive={{
                0: {
                    items: 1
                },
                600: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }}
        >
          {carouselData.map((item, index) => (
            <div 
              key={index} 
              className="relative flex flex-col items-center justify-between p-5 h-[100%]"
              style={{ 
                backgroundImage: `url(${item.image})`, 
                backgroundSize: 'cover', 
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                opacity: 0.9 
              }}
            >
              {/* Overlay for Mobile Screens */}
              <div className="absolute inset-0 bg-black opacity-50 md:hidden"></div>

              {/* Content Section */}
              <div className="relative text-left max-w-lg md:bg-transparent bg-white bg-opacity-80 p-4 md:p-0 rounded-lg">
                <h2 className="pp-bnr mb-4">{item.title}</h2>
                <p className="mb-6">{item.des}</p>
                <a
                  href={item.link}
                  className="button-m text-left inline-flex items-center rounded-md"
                >
                  <FontAwesomeIcon icon={item.icon} className="mr-1 lg:mr-2 ml-0" />
                  {item.buttonTitle}
                </a>
              </div>

              {/* Image Section for Desktop/Tablet */}
              <div className="hidden md:block md:relative w-full md:w-auto lg:w-auto mt-6 md:mt-0 md:ml-4">
                <img 
                  src={item.image} 
                  alt={item.title} 
                  className="rounded-md shadow-lg md:rounded-none md:shadow-none md:absolute md:top-0 md:right-0 md:h-full md:w-auto lg:w-[50%]"
                />
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
}

export default MainCarousel;
